import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: {
		boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
		borderRadius: 6,
		backgroundColor: '#fff'
	}
});

const Card = ({ children, className, ...rest }) => {
	const c = useStyles();
	return (
		<div className={clsx(c.root, className)} {...rest}>
			{children}
		</div>
	);
};

export default Card;
