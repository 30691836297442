import React from 'react';
import PropTypes from 'prop-types';
import SharedPlacesResource from '../../resources/SharedPlacesResource/SharedPlacesResource';
import PlaceDetailsResource from '../../resources/PlaceDetailsResource/PlaceDetailsResource';
import { Route, Switch } from 'react-router-dom';

const SharedPlaces = (props) => {
	const { history, match } = props;
	const handleOnPlaceClick = (p) => {
		history.push(`${match.path}/${p.id}`);
	};

	return (
		<Switch>
			<Route
				exact
				path={match.path}
				render={(props) => (
					<SharedPlacesResource {...props} onPlaceClick={handleOnPlaceClick} />
				)}
			/>
			<Route
				exact
				path={`${match.path}/:placeId`}
				render={(p) => <PlaceDetailsResource {...p} auth={props.auth} />}
			/>
		</Switch>
	);
};

SharedPlaces.propTypes = {
	auth: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

export default SharedPlaces;
