import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		color: 'black'
	}
});

const Row = ({ children, className, ...rest }) => {
	const c = useStyles(rest);
	return (
		<div className={clsx(c.root, className)} {...rest}>
			{children}
		</div>
	);
};

export default Row;
