import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Identicon from 'react-identicons';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexFlow: 'column',
		textAlign: 'center'
	},
	pictureContainer: {
		display: 'flex',
		justifyContent: 'center'
	},
	picture: {
		alignSelf: 'center',
		width: 150,
		height: 150,
		borderRadius: '100%',
		overflow: 'hidden',
		margin: '0 auto',
		marginTop: 24,
		marginBottom: 12,
		boxShadow: '0px 5px 32px -9px rgba(0,0,0,0.75)'
	}
});

const Profile = ({ auth, profile }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.pictureContainer}>
				<Identicon
					className={classes.picture}
					string={profile.name}
					size={150}
				/>
			</div>
			<h2>{profile.name}</h2>
			<h3>{profile.email}</h3>
		</div>
	);
};

export default Profile;
