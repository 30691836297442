import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import AppBar from '../ui/AppBar';
import Row from '../ui/Row';
import Button from '../ui/Button';
import IconButton from '../ui/IconButton';
import Popover from 'react-tiny-popover';
import Drawer from '../ui/Drawer';
import BottomBar from '../ui/BottomBar';
import Hidden from '../ui/Hidden';
import BottomActionButton from '../ui/BottomActionButton';

import ViewDashboardOutlineIcon from 'mdi-react/ViewDashboardIcon';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import HeartOutlineIcon from 'mdi-react/HeartOutlineIcon';
import MapMarkerPlusIcon from 'mdi-react/MapMarkerPlusIcon';
import SettingsOutlineIcon from 'mdi-react/SettingsOutlineIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import TimelapseIcon from 'mdi-react/TimelapseIcon';

import UserDialog from '../UserDialog';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexFlow: 'column'
	},
	appBarTitle: {
		marginLeft: 16,
		width: 32,
		height: 32,
		color: '#0b84e1'
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		flexGrow: 1
	},
	grow: {
		flexGrow: 1
	},
	bottomNavigation: {
		position: 'absolute',
		width: '100%',
		bottom: 0,
		zIndex: 1000
	},
	mainContainer: {
		height: 'calc(100vh - 56px)',
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column'
	},
	contentContainer: {
		overflow: 'hidden',
		overflowY: 'scroll',
		flexGrow: 1
	}
});

const useDrawerStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	listItem: {
		display: 'flex',
		alignItems: 'center',
		padding: 12,
		cursor: 'pointer',
		transition: 'background-color 0.3s ease',
		'&:hover': {
			backgroundColor: '#eaeaea'
		}
	},
	listIcon: {
		marginRight: 16
	},
	listItemText: {
		display: 'flex',
		flexDirection: 'column'
	},
	listItemTextPrimary: {
		fontWeight: 'bold'
	},
	listItemTextSecondary: {}
});

const DrawerList = ({ onOptionClick }) => {
	const c = useDrawerStyles();
	return (
		<div className={c.root}>
			<div className={c.listItem} onClick={(e) => onOptionClick('dashboard')}>
				<div className={c.listIcon}>
					<ViewDashboardOutlineIcon />
				</div>
				<div className={c.listItemText}>
					<span className={c.listItemTextPrimary}>Dashboard</span>
					<span className={c.listItemTextSecondary}>Saved places</span>
				</div>
			</div>
			<div className={c.listItem} onClick={(e) => onOptionClick('place')}>
				<div className={c.listIcon}>
					<MapMarkerPlusIcon />
				</div>
				<div className={c.listItemText}>
					<span className={c.listItemTextPrimary}>New place</span>
					<span className={c.listItemTextSecondary}>Create</span>
				</div>
			</div>
		</div>
	);
};

const AppLayout = ({ auth, children, history }) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleNavigationChange = (newValue) => {
		history.push(`/m/${newValue}`);
	};

	const handleSettingsClick = () => {
		history.push('/m/settings');
		setAnchorEl(null);
	};

	return (
		<div className={classes.root}>
			<AppBar className={classes.appBar}>
				<Row>
					<div className={classes.appBarTitle}>
						<TimelapseIcon size={32} />
					</div>
					<div className={classes.grow} />
					{!auth.isAuthenticated() && (
						<Button
							variant="transparent"
							onClick={() => history.push(`/m/login`)}>
							Login
						</Button>
					)}
					{auth.isAuthenticated() && (
						<>
							<Popover
								containerStyle={{ zIndex: 1000 }}
								isOpen={Boolean(anchorEl)}
								position={'bottom'} // preferred position
								onClickOutside={() => setAnchorEl(null)}
								content={
									<UserDialog
										username={auth.userProfile ? auth.userProfile.name : ''}
										photo={auth.userProfile ? auth.userProfile.picture : null}
										onSettingsClick={handleSettingsClick}
										onLogoutClick={() => auth.logout()}
									/>
								}>
								<IconButton
									className={classes.menuButton}
									color="#000"
									variant="transparent"
									aria-label="Menu"
									onClick={(e) =>
										anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget)
									}>
									<DotsVerticalIcon />
								</IconButton>
							</Popover>
						</>
					)}
				</Row>
			</AppBar>
			<main className={classes.content}>
				{auth.isAuthenticated() && (
					<Hidden showFrom="desktop">
						<Drawer>
							<DrawerList onOptionClick={handleNavigationChange} />
						</Drawer>
					</Hidden>
				)}
				<div className={classes.mainContainer}>
					<div className={classes.contentContainer}>{children}</div>
					{auth.isAuthenticated() && (
						<Hidden showBelow="tablet">
							<BottomBar
								className={classes.bottomNavigation}
								value={
									history.location.pathname.split('/')[
										history.location.pathname.split('/').length - 1
									]
								}
								onChange={handleNavigationChange}>
								<BottomActionButton
									className={classes.bottomNavigationAction}
									text="Dashboard"
									icon={<ViewDashboardOutlineIcon />}
									value="dashboard"
								/>
								<BottomActionButton
									className={classes.bottomNavigationAction}
									text="Shared"
									icon={<HeartOutlineIcon />}
									value="shared"
								/>
								<BottomActionButton
									className={classes.bottomNavigationAction}
									text="New"
									icon={<PlusIcon />}
									value="place"
								/>
								<BottomActionButton
									className={classes.bottomNavigationAction}
									text="Settings"
									icon={<SettingsOutlineIcon />}
									value="settings"
								/>
							</BottomBar>
						</Hidden>
					)}
				</div>
			</main>
		</div>
	);
};

export default AppLayout;
