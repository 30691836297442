import React, { useState } from 'react';
import PlaceDetails from '../../components/PlaceDetails';
import { getPlace } from '../../sandboxes/Sandbox';
import { connect } from 'react-redux';
import { firstOrUndefined } from '../../utils/utils';

const mapStateToProps = (state) => ({ sharedPlaces: state.sharedPlaces });

const PlaceDetailsResource = (props) => {
	const [requested, setRequested] = useState(false);
	const [placeData, setPlaceData] = useState(null);

	// placeData is the only prop to pass
	if (!placeData && !requested) {
		setRequested(true);

		// First filter by the cached on store.
		const placeFromStore = firstOrUndefined(
			props.sharedPlaces.filter((p) => p.id === props.match.params.placeId)
		);

		if (placeFromStore) {
			setPlaceData(placeFromStore);
		} else {
			getPlace(props.auth, props.match.params.placeId)
				.then((placeData) => {
					setPlaceData(placeData);
				})
				.catch((err) => console.error(err));
		}
	}

	if (!placeData) {
		return <h1>Loading...</h1>;
	}

	return <PlaceDetails {...props} placeData={placeData} />;
};

export default connect(mapStateToProps)(PlaceDetailsResource);
