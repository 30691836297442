import {
	PLACES_REQUESTED,
	PROFILE_REQUESTED
} from '../actions/Resources.actions';

const initialState = {
	placesRequested: false,
	profileRequested: false
};

export const resources = (state = initialState, action) => {
	switch (action.type) {
		case PLACES_REQUESTED:
			return { ...state, placesRequested: action.payload };
		case PROFILE_REQUESTED:
			return { ...state, profileRequested: action.payload };
		default:
			return state;
	}
};
