export function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
}

export const firstOrUndefined = (array) => {
	if (array.length > 0) {
		return array[0];
	} else return undefined;
};

export const isNullOrUndefined = (o) => {
	return o === null || o === undefined;
};

export const getPlaceDetail = (placeDetail, width = 500) => {
	if (
		placeDetail.hasOwnProperty('photos') &&
		placeDetail.photos.hasOwnProperty('groups') &&
		placeDetail.photos.groups.length > 0 &&
		placeDetail.photos.groups.filter((g) => g.count > 0).length > 0
	) {
		let photos = [];
		for (const g of placeDetail.photos.groups) {
			if (g.count > 0) {
				photos = photos.concat(
					g.items.map((p) => `${p.prefix}/width${width}${p.suffix}`)
				);
			}
		}
		return photos;
	} else {
		return [];
	}
};

export const obtainPlacePhotos = (photoResponse, width = 500) => {
	if (
		photoResponse.hasOwnProperty('photos') &&
		photoResponse.photos.hasOwnProperty('items') &&
		photoResponse.photos.items.length > 0
	) {
		return photoResponse.photos.items.map(
			(p) => `${p.prefix}width${width}${p.suffix}`
		);
	} else {
		return [];
	}
};
