const citiesApiUrl = 'https://owm-cities.now.sh/cities';
const CITY_KEY = 'CITY';

/**
 * Searches for cities.
 * @param {String} query - is the search query.
 */
export const getCities = (query) => {
	return new Promise((resolve, reject) => {
		if (!query || query.length === 0) {
			resolve([]);
		}
		let url = new URL(citiesApiUrl);
		let params = {
			query: query
		};
		Object.keys(params).forEach((key) =>
			url.searchParams.append(key, params[key])
		);
		fetch(url)
			.then((response) => {
				if (response.ok) {
					response.json().then((cities) => {
						resolve(
							cities.map((c) => {
								return {
									value: c.id,
									label: c.name + ', ' + c.country,
									data: c
								};
							})
						);
					});
				} else {
					reject(response.status);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/**
 * Saves a city into the local storage.
 * @param {Object} city - is the city object to be saved.
 */
export const saveCity = (city) => {
	localStorage.setItem(CITY_KEY, city ? city.label : '');
};

/**
 * Gets the saved city.
 */
export const getSavedCity = () => {
	return localStorage.getItem(CITY_KEY);
};
