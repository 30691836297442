import React from 'react';
import PropTypes from 'prop-types';
import withAuth from '../../hocs/auth/withAuth';
import ProfileResource from '../../resources/ProfileResource/ProfileResource';

const Settings = (props) => {
	const { auth } = props;
	return <ProfileResource auth={auth} />;
};

Settings.propTypes = {
	auth: PropTypes.object.isRequired
};

export default withAuth(Settings);
