import React from 'react';
import { withStyles } from '@material-ui/styles';
import AlertBoxIcon from 'mdi-react/AlertBoxIcon';

const styles = (theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexFlow: 'column',
		height: '100vh',
		width: '100%'
	},
	icon: {
		fontSize: 60
	},
	title: {},
	subtitle: {}
});

const PageNotFound = ({ classes }) => (
	<div className={classes.root}>
		<AlertBoxIcon className={classes.icon} />
		<h3>Hmm... that doesn't sound familiar.</h3>
		<p>Try again, maybe?</p>
	</div>
);

export default withStyles(styles)(PageNotFound);
