import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/styles';
import Places from '../../components/Places/Places';
import PlaceDetails from '../../components/PlaceDetails';
import PlaceDetailsResource from '../../resources/PlaceDetailsResource';
import PlaceEdit from '../../components/PlaceEdit';
import Responsive from 'react-responsive';
import windowSize from 'react-window-size';
import { isMobile } from '../../utils/screen';
import { Route, Switch } from 'react-router-dom';
import withAuth from '../../hocs/auth/withAuth';
import { connect } from 'react-redux';

import PlacesResource from '../../resources/PlacesResource';
import Modal from '../../components/ui/Modal/Modal';

const styles = () => ({
	drawerContainer: {
		width: 400
	}
});

const Desktop = (props) => <Responsive {...props} minWidth={768} />;
const Mobile = (props) => <Responsive {...props} maxWidth={767} />;

const mapStateToProps = (state) => ({
	shared: state.sharedPlaces
});

class Dashboard extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			places: [],
			placeData: {
				place: {},
				location: {
					lat: 0,
					lng: 0
				},
				schedule: {}
			},
			openDrawer: false
		};
	}

	goTo(route) {
		this.props.history.push(`${route}`);
	}

	handleOnPlaceClick = (placeData) => {
		this.setState({
			openDrawer: true,
			placeData
		});
		const { windowWidth } = this.props;
		if (isMobile(windowWidth)) {
			this.goTo(`${this.props.match.path}/${placeData.id}`);
		}
	};

	handleOnPlaceEdit = (p) => {
		const { match } = this.props;
		this.props.history.push(`${match.path}/${p.id}/edit`);
	};

	handleOnFinishingEdit = (placeData) => {
		// Save place data
	};

	render() {
		const { match, shared, auth, classes } = this.props;
		const { openDrawer, placeData } = this.state;

		return (
			<>
				<Switch>
					<Route
						exact
						path={match.path}
						render={(props) => (
							<>
								<Desktop>
									<PlacesResource
										auth={auth}
										onPlaceClick={this.handleOnPlaceClick}
										onEdit={this.handleOnPlaceEdit}
									/>
									{shared && shared.length > 0 && (
										<>
											<h3>Shared with me</h3>
											<Places
												places={shared}
												onPlaceClick={this.handleOnPlaceClick}
											/>
										</>
									)}
								</Desktop>
								<Mobile>
									<PlacesResource
										auth={auth}
										onPlaceClick={this.handleOnPlaceClick}
										onEdit={this.handleOnPlaceEdit}
									/>
								</Mobile>
								<Desktop>
									<Modal
										visible={openDrawer}
										width={500}
										onHide={() => this.setState({ openDrawer: false })}>
										<div
											className={classes.drawerContainer}
											tabIndex={0}
											role="button">
											<PlaceDetails
												auth={auth}
												placeData={placeData}
												onDelete={() => this.setState({ openDrawer: false })}
												onClose={() => this.setState({ openDrawer: false })}
											/>
										</div>
									</Modal>
								</Desktop>
							</>
						)}
					/>
					<Route
						exact
						path={`${match.path}/:placeId`}
						render={(props) => <PlaceDetailsResource {...props} auth={auth} />}
					/>
					<Route
						exact
						path={`${match.path}/:placeId/edit`}
						render={(props) => (
							<PlaceEdit
								{...props}
								auth={auth}
								onFinish={this.handleOnFinishingEdit}
								placeData={placeData}
							/>
						)}
					/>
				</Switch>
			</>
		);
	}
}

export default connect(mapStateToProps)(
	withStyles(styles)(windowSize(withAuth(Dashboard)))
);
