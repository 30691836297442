import { Observable } from 'rxjs';
import { from } from 'rxjs';

const baseUrl = 'https://source.unsplash.com/random/500x500?';
const SESSION_KEY = 'imageSeq';

export const getNewImageUrl = (query) => {
	if (!query) {
		return null;
	}
	const imageSeq = sessionStorage.getItem(SESSION_KEY);
	let seq = 0;
	if (imageSeq && imageSeq.length > 0 && !isNaN(imageSeq)) {
		seq = parseInt(imageSeq) + 1;
	}
	sessionStorage.setItem(SESSION_KEY, seq);
	return `${baseUrl}${query},${seq}`;
};

export const getNewImageUrlWith = (seq, query) => {
	if (!query) {
		return null;
	}
	return `${baseUrl}${query},${seq}`;
};

export const getNewImageUrlPromise = (query) =>
	new Promise((resolve, reject) => {
		if (!query || query.length === 0) {
			reject();
		} else {
			resolve(getNewImageUrl(query));
		}
	});

export const getNewImageUrl$ = (query) =>
	Observable.from(getNewImageUrl(query));

export const getImageUrl$ = (url) => {
	return from(fetch(url));
};

export const getSrcArray = (count, query) => {
	return Array.from(Array(count).keys()).map((i) => getNewImageUrl(query));
};
