export const capitalizeFirstLetter = (str) => {
	return str.charAt(0) + str.substring(1, str.length).toLowerCase();
};

export const getTimeZone = () => {
	return new Promise((resolve) => {
		resolve(Intl.DateTimeFormat().resolvedOptions().timeZone);
	});
};

export const getFormattedDayOfWeek = () => {
	const date = new Date();
	switch (date.getDay()) {
		case 1:
			return 'mon';
		case 2:
			return 'tue';
		case 3:
			return 'wed';
		case 4:
			return 'thu';
		case 5:
			return 'fri';
		case 6:
			return 'sat';
		case 0:
			return 'sun';
		default:
			break;
	}
};

/**
 * Checks if now is between two dates.
 * @param {Date} start - the starting date
 * @param {Date} end - the end date.
 */
export const isNowBetween = (now, start, end) => {
	return start.getTime() < now.getTime() && end > now.getTime();
};

export const converHoursToNowDates = (hourStart, hourEnd) => {
	let start = 0;
	let end = 0;
	if (hourStart.includes('PM')) {
		start = 12 + parseInt(hourStart.replace('PM').trim());
	}
	if (hourEnd.includes('PM')) {
		end = 12 + parseInt(hourEnd.replace('PM').trim());
	}

	const startDate = new Date();
	const endDate = new Date();
	startDate.setHours(start, 0, 0, 0);
	endDate.setHours(end, 0, 0, 0);
	return [startDate, endDate];
};

/**
 * Check if now hours is between two hours.
 * @param {string} hourStart - is the hour start formatted in AM?PM
 * @param {string} hourEnd - is the hour end formatted in AM/PM
 */
export const isNowBetweenHours = (now, hourStart, hourEnd) => {
	if (!hourStart || hourStart.length === 0 || !hourEnd || hourEnd.length === 0)
		return false;

	const [startDate, endDate] = converHoursToNowDates(hourStart, hourEnd);

	return isNowBetween(now, startDate, endDate);
};

/**
 * Enumerator for giving a state.
 */
export const STATE_TIME = {
	CLOSES_SOON: 'CLOSES_SOON',
	OPEN: 'OPEN',
	CLOSED: 'CLOSED',
	OPENS_SOON: 'OPENS_SOON'
};

/**
 * Returns the state from now and given hours.
 * @param {Date} now - is the now reference
 * @param {string} hourStart - is the start hour formatted
 * @param {string} hourEnd - is the end time formatted
 */
export const getStateFromNow = (now, hourStart, hourEnd) => {
	const [startDate, endDate] = converHoursToNowDates(hourStart, hourEnd);
	if (isNowBetween(now, startDate, endDate)) {
		if (now.getHours() - endDate.getHours() >= 1) {
			return STATE_TIME.CLOSES_SOON;
		} else {
			return STATE_TIME.OPEN;
		}
	} else {
		if (now.getHours() - startDate.getHours() <= 1) {
			return STATE_TIME.OPENS_SOON;
		} else {
			return STATE_TIME.CLOSED;
		}
	}
};
