import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Button from '../../ui/Button';

import ScheduleForm from '../ScheduleForm';

const styles = () => ({
	root: {
		display: 'flex',
		flexFlow: 'column'
	},
	formControl: {
		margin: 24
	},
	group: {
		margin: `8px 0`,
		display: 'flex',
		flexFlow: 'row'
	},
	closedContainer: {}
});

const applyClosedDays = (schedule, closed) => {
	const sched = { ...schedule };
	for (const c of Object.keys(closed)) {
		if (closed[c]) {
			sched[c].opens = '-';
			sched[c].closes = '-';
		}
	}
	return sched;
};

class NewPlaceStep2 extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			schedule: {
				mon: { opens: '7 AM', closes: '7 PM' },
				tue: { opens: '7 AM', closes: '7 PM' },
				wed: { opens: '7 AM', closes: '7 PM' },
				thu: { opens: '7 AM', closes: '7 PM' },
				fri: { opens: '7 AM', closes: '7 PM' },
				sat: { opens: '7 AM', closes: '7 PM' },
				sun: { opens: '7 AM', closes: '7 PM' }
			},
			closed: {
				mon: false,
				tue: false,
				wed: false,
				thu: false,
				fri: false,
				sat: false,
				sun: false
			}
		};
	}

	componentWillMount = () => {};

	handleChange = (event) => {
		this.setState({ value: event.target.value });
	};

	handleClosedChange = (day, isClosed) => {
		const { closed } = this.state;
		const c = {
			...closed,
			[day.toLowerCase()]: isClosed
		};
		this.setState({ closed: c });
	};

	handleScheduleChange = (schedule) => {
		this.setState({
			schedule: Object.assign({}, this.state.schedule, schedule)
		});
	};

	render() {
		const { classes, onComplete } = this.props;
		const { schedule, closed } = this.state;

		return (
			<div className={classes.root}>
				<ScheduleForm
					schedule={schedule}
					closed={closed}
					onScheduleChange={this.handleScheduleChange}
					onClosedChange={this.handleClosedChange}
				/>
				<Button
					color="primary"
					onClick={() => onComplete(applyClosedDays(schedule, closed))}>
					Finish
				</Button>
			</div>
		);
	}
}

NewPlaceStep2.propTypes = {
	onComplete: PropTypes.func.isRequired
};

NewPlaceStep2.defaultProps = {
	onComplete: () => {}
};

export default withStyles(styles)(NewPlaceStep2);
