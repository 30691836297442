import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: ({ width, open, placement }) => {
		if (open === undefined) {
			return {
				display: 'flex',
				width: width ? width : 240,
				height: 'calc(100vh - 96px)',
				borderRight: '2px solid #eaeaea'
			};
		} else {
			return {
				display: 'flex',
				width: width ? width : 240,
				position: 'absolute',
				[placement]: 0,
				height: '100%',
				top: 0,
				right: 0,
				overflowY: 'scroll',
				transform: open ? 'translateX(0)' : 'translateX(100%)',
				transition: 'transform 0.5s ease',
				backgroundColor: '#fff',
				zIndex: 1100
			};
		}
	},
	overlay: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		zIndex: 1050
	},
	container: {
		overflow: 'hidden'
	}
});

const Drawer = ({ className, children, onClose, ...rest }) => {
	const c = useStyles(rest);
	if (rest.open === undefined) {
		return <div className={clsx(c.root, className)}>{children}</div>;
	} else {
		return (
			<div className={c.container}>
				<div className={clsx(c.root, className)}>
					{rest.open ? children : null}
				</div>
				{rest.open && <div className={c.overlay} onClick={onClose} />}
			</div>
		);
	}
};

Drawer.propTypes = {
	open: PropTypes.bool,
	placement: PropTypes.oneOf(['left', 'right']),
	onClose: PropTypes.func
};

Drawer.defaultProps = {
	onClose: () => {}
};

export default Drawer;
