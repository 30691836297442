import React from 'react';
import PropTypes from 'prop-types';
import './RadioButton.css';

const RadioButton = ({
	id,
	name,
	label,
	checked,
	onChange,
	value,
	...rest
}) => {
	return (
		<div
			className="md-radio"
			onClick={(e) => onChange(e, !checked, value)}
			{...rest}>
			<input type="radio" name={name} checked={checked} />
			<label>{label}</label>
		</div>
	);
};

RadioButton.propTypes = {
	onChange: PropTypes.func
};

RadioButton.defaultProps = {
	onChange: () => {}
};

export default RadioButton;
