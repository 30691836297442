import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ImageItem from './../ImageItem/ImageItem';
import useWindowSize from '../../hooks/use-window-size';
import { isMobile } from '../../utils/screen';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, distinctUntilChanged } from 'rxjs/operators';
import useObservable from 'use-observable';

import Card from '../ui/Card';
import TextField from '../ui/TextField';
import IconButton from '../ui/IconButton';
import MagnifyIcon from 'mdi-react/MagnifyIcon';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexFlow: 'column'
	},
	imagesContainer: {
		display: 'flex',
		flexFlow: 'column',
		alignItems: 'center'
	},
	searchBar: {
		margin: '4px 8px',
		display: 'flex',
		alignItems: 'center'
	},
	input: {
		marginLeft: 8,
		flex: 1
	},
	iconButton: {
		padding: 10
	}
});

const query$ = new BehaviorSubject(null).pipe(
	debounceTime(300),
	filter((q) => q !== null && q.length > 0),
	distinctUntilChanged()
);

const ImageSelector = ({ onSelect, onSearch, imageUrls }) => {
	const classes = useStyles();
	const query = useObservable(query$, '');
	const size = useWindowSize();
	const [mobile] = useState(isMobile(size.width));

	useEffect(() => {
		const subs = query$.subscribe((q) => onSearch(q));
		return () => {
			subs.unsubscribe();
		};
	}, [onSearch]);

	return (
		<div className={classes.root}>
			<Card className={classes.searchBar}>
				<TextField
					variant="clean"
					defaultValue={query}
					onChange={(e) => query$.next(e.target.value)}
					className={classes.input}
					placeholder="Search by keyword"
				/>
				<IconButton
					variant="transparent"
					className={classes.iconButton}
					aria-label="Search">
					<MagnifyIcon />
				</IconButton>
			</Card>
			<div className={classes.imagesContainer}>
				{imageUrls &&
					imageUrls.length > 0 &&
					imageUrls.map((src) => (
						<ImageItem
							key={src}
							src={src}
							onSelected={(url) => onSelect(url)}
							width={mobile ? 200 : 300}
							height={mobile ? 200 : 300}
						/>
					))}
			</div>
		</div>
	);
};

ImageSelector.propTypes = {
	onSelect: PropTypes.func.isRequired,
	imageUrls: PropTypes.arrayOf(PropTypes.string)
};

export default ImageSelector;
