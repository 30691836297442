import { isNullOrUndefined } from 'util';
import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

const url = process.env.REACT_APP_BASE_URL;

/**
 * Shows a warning message if auth is not provided propperly.
 * @param {Object} auth - is the authentication object.
 */
const validateAuth = (auth) => {
	if (isNullOrUndefined(auth)) {
		console.warn(
			'Auth property is not provided. This could cause the API call to fail.'
		);
	}
};

/**
 * Obtains a new promise for querying the places of logged in user.
 * @param {Object} auth - is the authentication object.
 * @returns a Promise
 */
export const places = (auth) => {
	validateAuth(auth);
	return new Promise((resolve, reject) => {
		fetch(url + 'places', getOptions(auth))
			.then((response) => {
				if (response.ok) {
					response.json().then((data) => {
						resolve(data);
					});
				} else {
					reject(response);
				}
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

/**
 * Gets an observable for querying the places of logged in user.
 * @param {Object} auth - is the authentication object.
 * @returns an Observable
 */
export const places$ = (auth) => {
	validateAuth(auth);
	return ajax({
		...getOptions(auth),
		url: url + 'places'
	}).pipe(
		map((res) => res.response),
		catchError((e) => of(e))
	);
};

/**
 * Obtains a place using its id property.
 * @param {Object} auth - is the authentication object.
 * @param {string} id - is the place id
 * @returns a Promise.
 */
export const place = (auth, id) => {
	validateAuth(auth);
	return new Promise((resolve, reject) => {
		fetch(url + 'places/get', getOptionsWithData(auth, { placeId: id }))
			.then((response) => {
				if (response.ok) {
					response.json().then((data) => {
						resolve(data);
					});
				} else {
					reject(response);
				}
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

/**
 * Creates a new place posting into de API.
 * @param {Object} auth - is the authentication object.
 * @param {Object} placeData - is the object of the place
 * @returns a Promise
 */
export const postNewPlace = (auth, placeData) => {
	validateAuth(auth);
	return new Promise((resolve, reject) => {
		fetch(url + 'places/add', getOptionsWithData(auth, placeData))
			.then((response) => {
				if (response.ok) {
					response.json().then((data) => {
						resolve(data.message);
					});
				} else {
					reject(response);
				}
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

/**
 * Updates an existing place.
 * @param {Object} auth - is the authentication object.
 * @param {Object} place - is the place data
 * @param {Object} location - is the location data
 * @param {Object} schedule - is the schedule data
 * @returns a Promise.
 */
export const postPlace = (auth, place, location, schedule) => {
	validateAuth(auth);
	return new Promise((resolve, reject) => {
		fetch(
			url + 'places/update',
			getOptionsWithData(auth, { place, location, schedule })
		)
			.then((response) => {
				if (response.ok) {
					response.json().then((data) => {
						resolve(data.message);
					});
				} else {
					reject(response);
				}
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

/**
 * Deletes a place for the user.
 * @param {Object} auth - is the authentication object.
 * @param {string} placeId is the place identifier.
 * @returns a Promise.
 */
export const deletePlace = (auth, placeId) => {
	validateAuth(auth);
	return new Promise((resolve, reject) => {
		fetch(url + 'places/delete', getOptionsWithData(auth, { placeId }))
			.then((response) => {
				if (response.ok) {
					response.json().then((data) => {
						resolve(data.message);
					});
				} else {
					reject(response);
				}
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

/**
 * Obtains a list of users that matches the query.
 * @param {Object} auth - is the authentication object.
 * @param {string} query - is the search query
 * @returns a Promise
 */
export const users = (auth, query) => {
	validateAuth(auth);
	return new Promise((resolve, reject) => {
		fetch(url + 'users/search', getOptionsWithData(auth, { query }))
			.then((response) => {
				if (response.ok) {
					response.json().then((data) => {
						resolve(
							data.map((u) => ({ label: u.userName, value: u.userName }))
						);
					});
				} else {
					reject(response);
				}
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

/**
 * Returns an {@type Observable} Observable with the search of a user.
 * @param {Object} auth - is the authentication object
 * @param {String} query - is the search query
 * @returns an Observable
 */
export const users$ = (auth, query) => {
	validateAuth(auth);
	return ajax({
		...getOptionsWithData(auth, { query }),
		url: url + 'users/search'
	}).pipe(
		map((res) => res.response.map((u) => ({ label: u.name, value: u.name })))
	);
};

/**
 * Shares a place with a user, identified by its username.
 * @param {Object} auth - is the authentication object.
 * @param {string} user - is the username
 * @param {string} placeId - is the place id.
 * @returns an Observable.
 */
export const setSharedPlace$ = (auth, user, placeId) => {
	validateAuth(auth);
	return ajax({
		...getOptionsWithData(auth, { shareWith: user, placeId }),
		url: url + 'places/share'
	}).pipe(map((res) => res.response));
};

/**
 * Undo the sharing option for a place.
 * @param {Object} auth - is the authentication object.
 * @param {string} placeId - is the place id
 * @param {string} unshareWith - is the username
 * @returns an Observable
 */
export const setUnsharePlace$ = (auth, placeId, unshareWith) => {
	validateAuth(auth);
	return ajax({
		...getOptionsWithData(auth, { placeId, unshareWith }),
		url: url + 'places/unshare'
	}).pipe(map((res) => res.response));
};

/**
 * Gets the user profile for logged in user.
 * @param {Object} auth - is the authentication object.
 */
export const userProfile$ = (auth) => {
	validateAuth(auth);
	return ajax({
		...getOptions(auth),
		url: `${url}profile`
	}).pipe(map((res) => res.response));
};

/**
 * Updates a profile.
 * @param {Object} auth - is the authentication
 * @param {Object} profile - is the profile object.
 */
export const updateUserProfile$ = (auth, profile) => {
	validateAuth(auth);
	return ajax({
		...getOptionsWithData(auth, profile),
		url: `${url}profile/save`
	}).pipe(map((res) => res.response));
};

/**
 * Obtain all the used tags.
 * @param {Auth} auth - is the authentication object
 * @returns an Observable
 */
export const usedTags$ = (auth) => {
	validateAuth(auth);
	return ajax({ ...getOptions(auth), url: url + 'tags' }).pipe(
		map((res) => res.response)
	);
};

export const login$ = (email, password) => {
	return ajax({
		...getOptionsWithData(null, { email, password }),
		url: url + 'users/login'
	}).pipe(map((res) => res.response));
};

export const register$ = (email, username, password) => {
	return ajax({
		...getOptionsWithData(null, { email, username, password }),
		url: url + 'users/register'
	}).pipe(map((res) => res.response));
};

/****************************************************************/
/************************** Common ******************************/

/**
 * Obtains the options for sending data.
 * @param {Object} auth - is the authentication object.
 */
const getOptions = (auth) => {
	if (auth && auth.getAccessToken()) {
		return {
			method: 'POST',
			mode: 'cors',
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken()
			},
			credentials: 'include'
		};
	} else {
		return {
			method: 'POST',
			mode: 'cors'
		};
	}
};

/**
 * Gets the options with headers to be sent.
 * @param {Object} auth - is the authentication object.
 * @param {Object} body - is the body
 */
const getOptionsWithData = (auth, body) => {
	if (auth && auth.getAccessToken()) {
		return {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + auth.getAccessToken()
			},
			credentials: 'include',
			body: JSON.stringify(body)
		};
	} else {
		return {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		};
	}
};
