import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from 'mdi-react/CloseIcon';
import { getColor } from '../utils/colors';

const useStyles = makeStyles({
	root: ({ focused, color }) => ({
		backgroundColor: focused
			? color
				? getColor(color)
				: '#efefef'
			: color
			? getColor(color)
			: '#f2f2f2',
		borderRadius: 16,
		width: 'auto',
		height: 32,
		display: 'inline-flex',
		margin: 4,
		alignItems: 'center'
	}),
	title: {
		padding: 8,
		paddingLeft: 12
	},
	closeIcon: {
		borderRadius: '100%',
		backgroundColor: '#b9b9b9',
		width: 32,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#fff',
		cursor: 'pointer'
	}
});

const Chip = ({ title, onClose, ...rest }) => {
	const c = useStyles(rest);
	return (
		<div className={c.root} {...rest}>
			<span className={c.title}>{title}</span>
			{onClose && (
				<span className={c.closeIcon} onClick={onClose}>
					<CloseIcon size={14} />
				</span>
			)}
		</div>
	);
};

Chip.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string,
	onClose: PropTypes.func
};

Chip.defaultProps = {
	title: ''
};

export default Chip;
