import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Main from './containers/Main/Main';
import PageNotFound from './components/PageNotFound/PageNotFound';
import history from './utils/history';
import Auth from './hocs/auth/Auth';

import { Provider } from 'react-redux';
import store from './store/Store';
import './App.css';

const auth = new Auth();

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<Router history={history}>
					<Switch>
						<Route exact path="/" render={(props) => <Redirect to="/m" />} />
						<Route
							path="/m"
							render={(props) => <Main auth={auth} {...props} />}
						/>
						<Route component={PageNotFound} />
					</Switch>
				</Router>
			</Provider>
		);
	}
}

export default App;
