import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Card from '../ui/Card';
import Button from '../ui/Button';

const useStyles = makeStyles({
	card: {
		maxWidth: 345,
		maxHeight: 345
	},
	media: {
		height: 140,
		width: '100%',
		objectFit: 'cover'
	}
});

const PlaceDescription = ({ place, photo, onPlaceSelect }) => {
	const classes = useStyles();
	return (
		<Card className={classes.card}>
			<div>
				{photo.length > 0 ? (
					<img
						className={classes.media}
						src={photo}
						title={place.name}
						alt=""
					/>
				) : (
					<div className={classes.media} />
				)}
				<div>
					<h2>{place.name}</h2>
					<p component="p">{place.description}</p>
				</div>
			</div>
			<div>
				<Button
					variant="transparent"
					color="primary"
					onClick={() => onPlaceSelect(place)}>
					Select
				</Button>
			</div>
		</Card>
	);
};

PlaceDescription.propTypes = {
	place: PropTypes.object.isRequired,
	photo: PropTypes.string.isRequired
};

PlaceDescription.defaultProps = {
	place: {},
	photo: ''
};

export default PlaceDescription;
