import React from 'react';
import Loading from '../ui/Loading';
import { withStyles } from '@material-ui/styles';

const imagePlaceholderStyle = () => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'center',
		width: 300,
		height: 300,
		cursor: 'pointer',
		textAlign: 'center'
	},
	image: {
		width: 300,
		height: 300,
		borderRadius: 8,
		objectFit: 'cover',
		boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
	},
	placeholder: {
		width: 300,
		height: 300,
		borderRadius: 8,
		boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'center'
	},
	message: {
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'center',
		width: 300,
		height: 300,
		textAlign: 'center',
		opacity: 0,
		transition: '0.3s',
		position: 'absolute',
		background: 'rgba(0, 0, 0, 0.6)',
		borderRadius: 8,
		color: '#fff',
		'&:hover': {
			opacity: 1
		}
	},
	spinner: {
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: 300,
		height: 300,
		position: 'absolute'
	}
});

const ImagePlaceholder = ({ classes, image, onClick, onLoaded, loading }) => (
	<div className={classes.root} onClick={onClick}>
		{image ? (
			<img
				className={classes.image}
				src={image}
				alt={image}
				onLoad={() => onLoaded(image)}
			/>
		) : (
			<div className={classes.placeholder}>
				<h3>No image selected.</h3>
				<p>Click for select one from the gallery.</p>
			</div>
		)}
		{image && (
			<div className={classes.message}>
				<h3>Select another image</h3>
			</div>
		)}
		{loading && (
			<div className={classes.spinner}>
				<Loading className={classes.progress} />
			</div>
		)}
	</div>
);

ImagePlaceholder.defaultProps = {
	loading: false
};

export default withStyles(imagePlaceholderStyle)(ImagePlaceholder);
