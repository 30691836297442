import { Redirect } from 'react-router-dom';
import React from 'react';
import { isEmpty } from '../../utils/utils';

function withAuth(BaseComponent) {
	class ProtectedRoute extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				isAuthenticated: false,
				isLoading: true
			};
		}

		componentDidMount() {
			if (!this.props.auth) {
				console.error('Auth function is not provided!');
				return;
			}
			this.props.auth.validateSession$().subscribe((profile) => {
				if (isEmpty(profile)) {
					this.setState({ isAuthenticated: false, isLoading: false });
				} else {
					this.setState({ isAuthenticated: true, isLoading: false });
				}
			});
		}

		render() {
			const { isAuthenticated, isLoading } = this.state;
			const { from } = this.props.location.state || { from: { pathname: '/' } };

			return isLoading ? (
				<div>Loading...</div>
			) : isAuthenticated ? (
				<BaseComponent {...this.props} />
			) : (
				<Redirect to={from} {...this.props} />
			);
		}
	}

	return ProtectedRoute;
}

export default withAuth;
