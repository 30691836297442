import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import TextField from '../ui/TextField';
import Button from '../ui/Button/Button';
import { of } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexFlow: 'column',
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center'
	},
	inputLabel: {
		flexGrow: 1
	},
	formContainer: {
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'right'
	},
	form: {
		display: 'flex'
	}
});

const LoginPage = ({ auth }) => {
	const classes = useStyles();
	const emailRef = useRef(null);
	const passwordRef = useRef(null);
	const [loading, setLoading] = useState(false);

	const onGoClick = () => {
		setLoading(true);
		auth
			.login$(emailRef.current.value, passwordRef.current.value)
			.pipe(
				timeout(10000),
				catchError((err) => of('Timeout error'))
			)
			.subscribe((res) => {});
	};

	return (
		<div className={classes.root}>
			<h2>Login</h2>
			<div className={classes.formContainer}>
				<div className={classes.form}>
					<h4 className={classes.inputLabel}>Email</h4>
					<TextField inputRef={emailRef} type="text" />
				</div>
				<div className={classes.form}>
					<h4 className={classes.inputLabel}>Password</h4>
					<TextField inputRef={passwordRef} type="password" />
				</div>
			</div>
			<Button onClick={onGoClick} isLoading={loading}>
				Go
			</Button>
		</div>
	);
};

LoginPage.propTypes = {
	auth: PropTypes.object.isRequired
};

export default LoginPage;
