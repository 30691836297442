import React from 'react';
import PropTypes from 'prop-types';
// import { Spring, animated } from 'react-spring/renderprops';

import { makeStyles } from '@material-ui/styles';

import SettingsOutlineIcon from 'mdi-react/SettingsOutlineIcon';
import ExitToAppIcon from 'mdi-react/ExitToAppIcon';
import Identicon from 'react-identicons';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexFlow: 'column',
		maxWidth: 250,
		width: 250,
		backgroundColor: 'white',
		boxShadow: '0px 7px 10px 3px #00000055',
		margin: 16
	},
	avatar: {
		alignSelf: 'center',
		width: 100,
		height: 100,
		borderRadius: '100%',
		overflow: 'hidden',
		margin: '0 auto',
		marginTop: 24,
		marginBottom: 12,
		boxShadow: '0px 5px 32px -9px rgba(0,0,0,0.75)'
	},
	user: {
		marginTop: 12,
		marginBottom: 12,
		textAlign: 'center'
	},
	row: {
		display: 'flex',
		alignContent: 'center',
		padding: 12,
		cursor: 'pointer',
		transition: 'background-color 0.3s ease',
		'&:hover': {
			backgroundColor: '#eaeaea'
		}
	}
});

const UserDialog = ({ username, onSettingsClick, onLogoutClick }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Identicon className={classes.avatar} string={username} size={150} />
			<h4 className={classes.user}>{username}</h4>
			<div>
				<div className={classes.row} onClick={onSettingsClick}>
					<SettingsOutlineIcon /> Settings
				</div>
				<div className={classes.row} onClick={onLogoutClick}>
					<ExitToAppIcon /> Logout
				</div>
			</div>
		</div>
	);
};

UserDialog.propTypes = {
	username: PropTypes.string.isRequired,
	onSettingsClick: PropTypes.func.isRequired,
	onLogoutClick: PropTypes.func.isRequired
};

UserDialog.defaultProps = {
	username: '',
	onSettingsClick: () => {},
	onLogoutClick: () => {}
};

export default UserDialog;
