import { getPlacesByQuery } from '../services/Foursquare';
import {
	postNewPlace,
	places,
	place,
	postPlace,
	deletePlace,
	usedTags$,
	users,
	users$,
	setSharedPlace$,
	places$,
	setUnsharePlace$,
	userProfile$,
	updateUserProfile$,
	login$,
	register$
} from '../services/PlacesApi';
import { assignTemporalId } from '../utils/Update';
import {
	completeAddPlaces,
	addPlaces,
	unsharePlaces,
	sharePlaces
} from '../store/actions/Places.actions';
import { removeSharedPlaces } from '../store/actions/SharedPlaces.actions';
import { from } from 'rxjs';
import { tap } from 'rxjs/operators';
import store from '../store/Store';
import { setProfile } from '../store/actions/Profile.actions';

export const getPlaces = (city, query) => {
	return getPlacesByQuery(city, query);
};

export const getPlaces$ = (city, query) => {
	return from(getPlacesByQuery(city, query));
};

export const getPlacesList = (auth) => {
	return places(auth);
};

export const getPlacesList$ = (auth) => {
	return places$(auth);
};

export const getPlace = (auth, id) => {
	return place(auth, id);
};

export const getPlace$ = (auth, id) => {
	return from(place(auth, id));
};

export const getPlacesForSearchBar = (city, query) => {
	return new Promise((res) => {
		getPlacesByQuery(city, query).then((data) => {
			res(data.response.venues.map((p) => buildPlaceForOptions(p)));
		});
	});
};

export const saveNewPlace = (auth, placeData) => {
	const newPlace = assignTemporalId(placeData);
	store.dispatch(addPlaces(newPlace));
	return postNewPlace(auth, newPlace).then((p) => {
		store.dispatch(completeAddPlaces(p));
	});
};

export const saveNewPlace$ = (auth, placeData) => {
	return from(saveNewPlace(auth, placeData));
};

export const savePlace = (auth, place, location, schedule) => {
	return postPlace(auth, place, location, schedule);
};

export const removePlace = (auth, placeId) => {
	return deletePlace(auth, placeId);
};

export const getUsedTags$ = (auth) => {
	return usedTags$(auth);
};

export const searchUsers = (auth, query) => {
	return users(auth, query);
};

export const searchUsers$ = (auth, query) => {
	return users$(auth, query);
};

export const sharePlace$ = (auth, user, placeId) => {
	return setSharedPlace$(auth, user, placeId).pipe(
		tap((m) => {
			store.dispatch(sharePlaces({ placeId, user }));
		})
	);
};

export const unsharePlace$ = (auth, placeId, unshareWith) => {
	return setUnsharePlace$(auth, placeId, unshareWith).pipe(
		tap((m) => {
			if (!unshareWith) {
				store.dispatch(removeSharedPlaces({ id: placeId }));
			} else {
				store.dispatch(unsharePlaces({ placeId, user: unshareWith }));
			}
		})
	);
};

export const getUserProfile$ = (auth) => {
	return userProfile$(auth).pipe(
		tap((profile) => {
			if (profile) {
				store.dispatch(setProfile({ profile }));
			}
		})
	);
};

export const saveUserProfile$ = (auth, profile) => {
	return updateUserProfile$(auth, profile).pipe(
		tap((p) => {
			if (p) {
				store.dispatch(setProfile(profile));
			}
		})
	);
};

export const loginUser$ = (email, password) => {
	return login$(email, password);
};

export const registerUser$ = (email, username, password) => {
	return register$(email, username, password);
};

const buildPlaceForOptions = (placeData) => ({
	label: placeData.name,
	value: placeData.id,
	data: placeData
});
