import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import './Loading.css';

const useStyles = makeStyles({
	root: ({ size, color }) => ({
		'& span': {
			display: 'inline-block',
			width: size * 5,
			height: size * 5,
			borderRadius: '100%',
			backgroundColor: color,
			margin: '15px 5px'
		},
		'& span:nth-child(1)': {
			animation: 'bounce 1s ease-in-out infinite'
		},
		'& span:nth-child(2)': {
			animation: 'bounce 1s ease-in-out 0.33s infinite'
		},
		'& span:nth-child(3)': {
			animation: 'bounce 1s ease-in-out 0.66s infinite'
		}
	})
});

const Loading = ({ className, ...rest }) => {
	const c = useStyles(rest);
	return (
		<div className={clsx(c.root, className)}>
			<span />
			<span />
			<span />
		</div>
	);
};

Loading.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string
};

Loading.defaultProps = {
	size: 1,
	color: '#fff'
};

export default Loading;
