import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import Checkbox from '../../ui/Checkbox';
import RadioButton from '../../ui/RadioButton';

import ScheduleDay from '../../ScheduleDay/ScheduleDay';

// const hour = [
// 	'12 AM',
// 	'1 AM',
// 	'2 AM',
// 	'3 AM',
// 	'4 AM',
// 	'5 AM',
// 	'6 AM',
// 	'7 AM',
// 	'8 AM',
// 	'9 AM',
// 	'10 AM',
// 	'11 AM',
// 	'12 PM',
// 	'1 PM',
// 	'2 PM',
// 	'3 PM',
// 	'4 PM',
// 	'5 PM',
// 	'6 PM',
// 	'7 PM',
// 	'8 PM',
// 	'9 PM',
// 	'10 PM',
// 	'11 PM'
// ];

const ScheduleMonFri = ({ onChange, schedule, closed }) => {
	const groupClosed =
		closed['mon'] &&
		closed['tue'] &&
		closed['wed'] &&
		closed['thu'] &&
		closed['fri'];
	return (
		<>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						mon: { opens, closes },
						tue: { opens, closes },
						wed: { opens, closes },
						thu: { opens, closes },
						fri: { opens, closes }
					})
				}
				initialOpens={schedule.mon.opens}
				initialCloses={schedule.mon.closes}
				name={'Mon - Fri'}
				closed={groupClosed}
			/>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						sat: { opens, closes }
					})
				}
				initialOpens={schedule.sat.opens}
				initialCloses={schedule.sat.closes}
				name={'Sat'}
				closed={closed['sat']}
			/>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						sun: { opens, closes }
					})
				}
				initialOpens={schedule.sun.opens}
				initialCloses={schedule.sun.closes}
				name={'Sun'}
				closed={closed['sun']}
			/>
		</>
	);
};

const ScheduleMonSat = ({ onChange, schedule, closed }) => {
	const groupClosed =
		closed['mon'] &&
		closed['tue'] &&
		closed['wed'] &&
		closed['thu'] &&
		closed['fri'] &&
		closed['sat'];
	return (
		<>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						mon: { opens, closes },
						tue: { opens, closes },
						wed: { opens, closes },
						thu: { opens, closes },
						fri: { opens, closes },
						sat: { opens, closes }
					})
				}
				initialOpens={schedule.mon.opens}
				initialCloses={schedule.mon.closes}
				name={'Mon - Sat'}
				closed={groupClosed}
			/>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						sun: { opens, closes }
					})
				}
				initialOpens={schedule.sun.opens}
				initialCloses={schedule.sun.closes}
				name={'Sun'}
				closed={closed['sun']}
			/>
		</>
	);
};

const ScheduleMonSun = ({ onChange, schedule, closed }) => {
	const groupClosed =
		closed['mon'] &&
		closed['tue'] &&
		closed['wed'] &&
		closed['thu'] &&
		closed['fri'] &&
		closed['sat'] &&
		closed['sun'];
	return (
		<>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						mon: { opens, closes },
						tue: { opens, closes },
						wed: { opens, closes },
						thu: { opens, closes },
						fri: { opens, closes },
						sat: { opens, closes },
						sun: { opens, closes }
					})
				}
				initialOpens={schedule.mon.opens}
				initialCloses={schedule.mon.closes}
				name={'Mon - Sun'}
				closed={groupClosed}
			/>
		</>
	);
};

const ScheduleAllDays = ({ onChange, schedule, closed }) => {
	return (
		<>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						mon: { opens, closes }
					})
				}
				initialOpens={schedule.mon.opens}
				initialCloses={schedule.mon.closes}
				name={'Mon'}
				closed={closed['mon']}
			/>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						tue: { opens, closes }
					})
				}
				initialOpens={schedule.tue.opens}
				initialCloses={schedule.tue.closes}
				name={'Tue'}
				closed={closed['tue']}
			/>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						wed: { opens, closes }
					})
				}
				initialOpens={schedule.wed.opens}
				initialCloses={schedule.wed.closes}
				name={'Wed'}
				closed={closed['wed']}
			/>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						thu: { opens, closes }
					})
				}
				initialOpens={schedule.thu.opens}
				initialCloses={schedule.thu.closes}
				name={'Thu'}
				closed={closed['thu']}
			/>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						fri: { opens, closes }
					})
				}
				initialOpens={schedule.fri.opens}
				initialCloses={schedule.fri.closes}
				name={'Fri'}
				closed={closed['fri']}
			/>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						sat: { opens, closes }
					})
				}
				initialOpens={schedule.sat.opens}
				initialCloses={schedule.sat.closes}
				name={'Sat'}
				closed={closed['sat']}
			/>
			<ScheduleDay
				onChange={(opens, closes) =>
					onChange({
						sun: { opens, closes }
					})
				}
				initialOpens={schedule.sun.opens}
				initialCloses={schedule.sun.closes}
				name={'Sun'}
				closed={closed['sun']}
			/>
		</>
	);
};

const styles = () => ({
	root: {
		display: 'flex',
		flexFlow: 'column'
	},
	formControl: {
		margin: 24
	},
	group: {
		margin: `8px 0`,
		display: 'flex',
		flexFlow: 'row'
	},
	closedContainer: {}
});

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

class ScheduleForm extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			schedule: props.schedule,
			value: '0'
		};
	}

	componentWillMount = () => {};

	handleChange = (event, checked, value) => {
		this.setState({ value });
	};

	handleClosedChange = (day, isClosed) => {
		this.props.onClosedChange(day, isClosed);
	};

	handleScheduleChange = (schedule) => {
		this.props.onScheduleChange(schedule);
	};

	render() {
		const { classes, schedule, closed } = this.props;
		const { value } = this.state;

		return (
			<div className={classes.root}>
				<div className={classes.formControl}>
					<h3>Set same hours in:</h3>
					<div className={classes.group}>
						<RadioButton
							value="5"
							label="Mon - Fri"
							name="sameHours"
							onChange={this.handleChange}
							checked={value === '5'}
						/>
						<RadioButton
							value="6"
							label="Mon - Sat"
							name="sameHours"
							onChange={this.handleChange}
							checked={value === '6'}
						/>
						<RadioButton
							value="7"
							label="All days"
							name="sameHours"
							onChange={this.handleChange}
							checked={value === '7'}
						/>
						<RadioButton
							value="0"
							label="Manual"
							name="sameHours"
							onChange={this.handleChange}
							checked={value === '0'}
						/>
					</div>
				</div>
				{value === '5' && (
					<ScheduleMonFri
						onChange={this.handleScheduleChange}
						schedule={schedule}
						closed={closed}
					/>
				)}
				{value === '6' && (
					<ScheduleMonSat
						onChange={this.handleScheduleChange}
						schedule={schedule}
						closed={closed}
					/>
				)}
				{value === '7' && (
					<ScheduleMonSun
						onChange={this.handleScheduleChange}
						schedule={schedule}
						closed={closed}
					/>
				)}
				{value === '0' && (
					<ScheduleAllDays
						onChange={this.handleScheduleChange}
						schedule={schedule}
						closed={closed}
					/>
				)}
				<div className={classes.formControl}>
					<h3>It closes on:</h3>
					<div className={classes.closedContainer}>
						{days.map((d) => (
							<Checkbox
								key={d}
								checked={closed[d.toLowerCase()]}
								onChange={(e) => this.handleClosedChange(d, e.target.checked)}
								value={d}
								title={d}
							/>
						))}
					</div>
				</div>
			</div>
		);
	}
}

ScheduleForm.propTypes = {
	schedule: PropTypes.object,
	closed: PropTypes.object,
	onScheduleChange: PropTypes.func,
	onClosedChange: PropTypes.func
};

ScheduleForm.defaultProps = {
	schedule: {
		mon: { opens: '7 AM', closes: '7 PM' },
		tue: { opens: '7 AM', closes: '7 PM' },
		wed: { opens: '7 AM', closes: '7 PM' },
		thu: { opens: '7 AM', closes: '7 PM' },
		fri: { opens: '7 AM', closes: '7 PM' },
		sat: { opens: '7 AM', closes: '5 PM' },
		sun: { opens: '7 AM', closes: '5 PM' }
	},
	closed: {
		mon: false,
		tue: false,
		wed: false,
		thu: false,
		fri: false,
		sat: false,
		sun: false
	},
	onScheduleChange: () => {},
	onClosedChange: () => {}
};

export default withStyles(styles)(ScheduleForm);
