import React, { useState } from 'react';
import Places from '../../components/Places/Places';
import Loading from '../../components/ui/Loading';
import FullScreen from '../../components/ui/FullScreen';
import { connect } from 'react-redux';
import { getPlacesList$ } from '../../sandboxes/Sandbox';

import { addBulkPlaces } from '../../store/actions/Places.actions';
import { addBulkSharedPlaces } from '../../store/actions/SharedPlaces.actions';
import { setPlacesRequested } from '../../store/actions/Resources.actions';
import { makeStyles } from '@material-ui/styles';

const mapStateToProps = (state) => ({
	places: state.places,
	resources: state.resources
});

const mapDispatchToProps = (dispatch) => ({
	onPlaceBulkAdd: (places) => {
		dispatch(addBulkPlaces(places));
	},
	onSharedPlaceBulkAdd: (places) => {
		dispatch(addBulkSharedPlaces(places));
	},
	onPlacesRequested: () => {
		dispatch(setPlacesRequested());
	}
});

const useStyles = makeStyles({
	root: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	},
	fullScreen: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
});

const PlacesResource = ({
	places,
	resources,
	onPlaceBulkAdd,
	onSharedPlaceBulkAdd,
	onPlacesRequested,
	...rest
}) => {
	const [requested, setRequested] = useState(false);
	const [error, setError] = useState(null);
	const classes = useStyles();

	// placeData is the only prop to pass
	if (!requested && !resources.placesRequested) {
		setRequested(true);
		getPlacesList$(rest.auth).subscribe((response) => {
			if (response.name === 'AjaxError') {
				setError(response);
			} else {
				onPlaceBulkAdd(response.places);
				onSharedPlaceBulkAdd(response.shared);
				onPlacesRequested();
			}
		});
	}

	let msg = '';

	if (resources.placesRequested && !error) {
		if (places.length === 0) {
			msg = 'You do not have added places.';
		}
	} else if (error) {
		msg = 'An error ocurred.';
	}

	// if (!resources.placesRequested && !error) {
	// 	return <Loading />;
	// } else if (resources.placesRequested && !error) {
	// 	if (places.length === 0) {
	// 		return <h1>You do not have added places.</h1>;
	// 	}
	// } else if (error) {
	// 	return <h1>An error ocurred.</h1>;
	// } else {
	// 	return <Places places={places} {...rest} />;
	// }

	return (
		<div className={classes.root}>
			{!resources.placesRequested && !error && (
				<FullScreen>
					<Loading size={3} color="#0b84e1" />
				</FullScreen>
			)}
			{msg.length > 0 && <h4>{msg}</h4>}
			{resources.placesRequested && !error && places.length > 0 && (
				<Places places={places} {...rest} />
			)}
		</div>
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PlacesResource);
