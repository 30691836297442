import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getPlacesList$ } from '../../sandboxes/Sandbox';

import Places from '../../components/Places/Places';

import { addBulkPlaces } from '../../store/actions/Places.actions';
import { addBulkSharedPlaces } from '../../store/actions/SharedPlaces.actions';
import { setPlacesRequested } from '../../store/actions/Resources.actions';

const mapStateToProps = (state) => ({
	sharedPlaces: state.sharedPlaces,
	resources: state.resources
});

const mapDispatchToProps = (dispatch) => ({
	onPlaceBulkAdd: (places) => {
		dispatch(addBulkPlaces(places));
	},
	onSharedPlaceBulkAdd: (places) => {
		dispatch(addBulkSharedPlaces(places));
	},
	onPlacesRequested: () => {
		dispatch(setPlacesRequested());
	}
});

const SharedPlacesResource = ({
	sharedPlaces,
	resources,
	onPlaceBulkAdd,
	onSharedPlaceBulkAdd,
	onPlacesRequested,
	...rest
}) => {
	const [requested, setRequested] = useState(false);
	const [error, setError] = useState(null);

	// placeData is the only prop to pass
	if (!requested && !resources.placesRequested) {
		setRequested(true);
		getPlacesList$(rest.auth).subscribe((response) => {
			if (response.name === 'AjaxError') {
				setError(response);
			} else {
				onPlaceBulkAdd(response.places);
				onSharedPlaceBulkAdd(response.shared);
				onPlacesRequested();
			}
		});
	}

	if (!resources.placesRequested && !error) {
		return <h1>Loading...</h1>;
	} else if (resources.placesRequested && !error) {
		if (sharedPlaces.length === 0) {
			return <h1>You do not have added places.</h1>;
		}
	} else if (error) {
		return <h1>An error ocurred.</h1>;
	}

	return <Places places={sharedPlaces} {...rest} />;
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SharedPlacesResource);
