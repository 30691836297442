import { ID } from './Id';

const property = '_tempId';

export const firstOrDefault = (tree, tempId) => {
	let filtered = tree.filter((t) =>
		t[property] !== undefined && t[property] === tempId ? undefined : t
	);
	if (filtered && filtered.length >= 1) {
		return filtered[0];
	} else {
		return undefined;
	}
};

/**
 * Get the updated array.
 * @param {Array} array - is the overall array to search for temporal id.
 * @param {any} tempId - is the temporal id assigned.
 * @param {any} updatedId - is the updated id to be assigned.
 */
export const getUpdatedArray = (array, tempId, updatedId) => {
	let obj = firstOrDefault(array, tempId);
	let i = array
		.map((t) => (t[property] === undefined ? 0 : t[property]))
		.indexOf(tempId);

	delete obj[property];
	obj.id = updatedId;

	return [...array.slice(0, i), Object.assign({}, obj), ...array.slice(i + 1)];
};

/**
 * Cleans temporal id property.
 * @param {Object} obj - is the object to clean.
 * @returns a copy of the object without the temporal id property.
 */
export const cleanCreatedObject = (obj) => {
	let o = Object.assign({}, obj);
	delete o[property];
	return o;
};

/**
 * Assign a temporal identifier.
 * @param {Object} obj - is the object to be assigned.
 * @returns a copy of the object with the temporal identifier assigned.
 */
export const assignTemporalId = (obj) => {
	let o = Object.assign({}, obj);
	o[property] = getNewId();
	o.id = o[property];
	return o;
};

export const getNewId = () => {
	return ID();
};

export const emptyPromise = () => new Promise((resolve) => resolve());
