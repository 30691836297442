import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import ImageSelector from './../../ImageSelector/ImageSelector';
import Fab from '../../ui/Fab';
import { getSrcArray } from './../../../services/Unsplash';
import ReactModal from 'react-modal';
import RefreshIcon from 'mdi-react/RefreshIcon';

const styles = () => ({
	imageDialog: {
		width: 500,
		minWidth: 300,
		height: '80vh',
		backgroundColor: 'white',
		position: 'absolute',
		boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: 12,
		overflowY: 'scroll'
	},
	fab: {
		margin: '0 auto',
		bottom: 16,
		zIndex: 401,
		position: 'sticky',
		width: 56,
		height: 56
	}
});

const ImageSelectDialog = ({ classes, open, onSelect, onClose }) => {
	const [imageUrls, setImageUrls] = useState([]);
	const [isSearching, setIsSearching] = useState(false);
	const [query, setQuery] = useState(null);

	useEffect(() => {
		if (query && query.length > 0) setImageUrls(getSrcArray(10, query));
	}, [query]);

	return (
		<ReactModal
			ariaHideApp={true}
			className={classes.imageDialog}
			isOpen={open}
			onRequestClose={() => onClose()}
			aria-labelledby="simple-dialog-title">
			<h4 id="simple-dialog-title">Select an image</h4>
			<div>
				{open && (
					<ImageSelector
						onSelect={onSelect}
						imageUrls={imageUrls}
						onSearch={(q) => {
							setIsSearching(true);
							setQuery(q);
						}}
					/>
				)}
			</div>
			{isSearching && (
				<div className={classes.fab}>
					<Fab
						color="primary"
						aria-label="Save"
						onClick={() => {
							if (query && query.length > 0)
								setImageUrls(getSrcArray(10, query));
						}}>
						<RefreshIcon />
					</Fab>
				</div>
			)}
		</ReactModal>
	);
};

export default withStyles(styles)(ImageSelectDialog);
