import React, { useState } from 'react';
import { getUserProfile$ } from '../../sandboxes/Sandbox';
import { catchError } from 'rxjs/operators';
import { connect } from 'react-redux';
import Profile from '../../components/Profile/Profile';
import Loading from '../../components/ui/Loading';
import FullScreen from '../../components/ui/FullScreen';

const mapStateToProps = (state) => ({
	resources: state.resources
});

const ProfileResource = ({ resources, ...props }) => {
	const [requested, setRequested] = useState(false);
	const [error, setError] = useState(false);
	const [profile, setProfile] = useState(null);

	if (!requested) {
		setRequested(true);
		getUserProfile$(props.auth)
			.pipe(
				catchError((err) => {
					console.error(err);
					setError(true);
				})
			)
			.subscribe((profile) => {
				setProfile(profile);
			});
	}

	if (error) {
		return <h1>An error has occurred.</h1>;
	} else if (profile) {
		return <Profile {...props} profile={profile} />;
	}
	return (
		<FullScreen>
			<Loading color="#0b84e1" size={3} />
		</FullScreen>
	);
};

export default connect(mapStateToProps)(ProfileResource);
