import React, { useState } from 'react';

import TextField from '../ui/TextField';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexFlow: 'row'
	},
	textField: {
		marginLeft: 8,
		marginRight: 8
	}
});

const ScheduleDay = ({
	name,
	onChange,
	closed,
	initialOpens,
	initialCloses
}) => {
	const [opens, setOpens] = useState(initialOpens);
	const [closes, setCloses] = useState(initialCloses);
	const classes = useStyles();

	const handleOpens = (value) => {
		setOpens(value);
		onChange(value, closes);
	};

	const handleCloses = (value) => {
		setCloses(value);
		onChange(opens, value);
	};

	if (opens !== initialOpens) {
		setOpens(initialOpens);
	}

	if (closes !== initialCloses) {
		setCloses(initialCloses);
	}

	return (
		<div className={classes.root}>
			<h6>{name}</h6>
			<TextField
				id="outlined-name-opens"
				label="Opens"
				placeholder="Opens"
				className={classes.textField}
				value={closed ? '-' : opens}
				onChange={(e) => handleOpens(e.target.value)}
				margin="normal"
				variant="outlined"
				disabled={closed}
			/>
			<TextField
				id="outlined-name-closes"
				label="Closes"
				placeholder="Closes"
				className={classes.textField}
				value={closed ? '-' : closes}
				onChange={(e) => handleCloses(e.target.value)}
				margin="normal"
				variant="outlined"
				disabled={closed}
			/>
		</div>
	);
};

ScheduleDay.propTypes = {
	// bla: PropTypes.string,
};

ScheduleDay.defaultProps = {
	closed: false,
	onChange: () => {}
};

export default ScheduleDay;
