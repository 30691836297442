import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

const url = process.env.REACT_APP_FOURSQUARE_URL;
const id = process.env.REACT_APP_FOURSQUARE_ID;
const secret = process.env.REACT_APP_FOURSQUARE_SECRET;

const searchUri = 'venues/search';
const detailsUri = 'venues/';
const clientInfo = `client_id=${id}&client_secret=${secret}`;

export const getPlacesByQuery = (city, query) => {
	return new Promise((resolve, reject) => {
		fetch(
			`${url}${searchUri}?near=${city}&${clientInfo}&query=${query}&v=20190417`
		)
			.then((response) => {
				if (response.ok) {
					response.json().then((data) => {
						resolve(data);
					});
				} else {
					reject(response);
				}
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

export const getPlaceDetail = (place) => {
	return new Promise((resolve, reject) => {
		fetch(`${url}${detailsUri}${place.id}?${clientInfo}&v=20190417`)
			.then((response) => {
				if (response.ok) {
					response.json().then((data) => {
						resolve(data.response.venue);
					});
				} else {
					reject(response);
				}
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

export const getPhotos = (place) => {
	return new Promise((resolve, reject) => {
		fetch(`${url}${detailsUri}${place.id}/photos?${clientInfo}&v=20190417`)
			.then((response) => {
				if (response.ok) {
					response.json().then((data) => {
						resolve(data.response);
					});
				} else {
					reject(response);
				}
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

/**
 * Obtains the photos from a given place.
 * @param {Object} place - is the place response from Foursquare.
 */
export const getPhotos$ = (place) => {
	return ajax
		.getJSON(`${url}${detailsUri}${place.id}/photos?${clientInfo}&v=20190417`)
		.pipe(map((res) => res.response));
};
