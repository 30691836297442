import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseIcon from 'mdi-react/CloseIcon';
import './Modal.css';

const Modal = ({
	visible,
	onHide,
	closeIcon,
	children,
	actions,
	...otherProps
}) => {
	const className = classNames('overlay', { visible: visible });
	return (
		visible && (
			<div className={className} onClick={onHide}>
				<div
					className="root"
					onClick={(e) => e.stopPropagation()}
					{...otherProps}>
					{closeIcon && <CloseIcon className="close" onClick={onHide} />}
					{visible && <div className="inner">{children}</div>}
					{actions && <div className="actions">{actions}</div>}
				</div>
			</div>
		)
	);
};

Modal.propTypes = {
	/** Whether the modal is visible or not */
	visible: PropTypes.bool,
	/** Handle the hide event */
	onHide: PropTypes.func.isRequired,
	/** Whether to show an X in the corner */
	closeIcon: PropTypes.bool,
	/** Any content to show at the bottom of the modal */
	actions: PropTypes.oneOfType([PropTypes.node, PropTypes.array])
};

export default Modal;
