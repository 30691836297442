import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import PlaceMap from '../../containers/PlaceMap/PlaceMap';
import ScheduleForm from '../../components/NewPlaceForm/ScheduleForm/ScheduleForm';
import { getPlace, savePlace, getUsedTags$ } from './../../sandboxes/Sandbox';
import { isEmpty } from '../../utils/utils';

import NotFound from './../NotFound/NotFound';
import { connect } from 'react-redux';
import { updatePlaces } from '../../store/actions/Places.actions';
import ImagePlaceholder from './../ImagePlaceholder/ImagePlaceholder';
import ImageSelectDialog from './../dialogs/ImageSelectDialog/index';
import ChipInput from '../ChipInput/ChipInput';
import Fab from '../ui/Fab';
import TextField from '../ui/TextField';
import ContentSaveIcon from 'mdi-react/ContentSaveIcon';

const styles = () => ({
	root: {
		display: 'flex',
		flexFlow: 'column'
	},
	newPlaceContainer: {
		display: 'flex',
		flexFlow: 'column'
	},
	textField: {
		marginLeft: 8,
		marginRight: 8
	},
	mapContainer: {
		width: '100%',
		height: 400
	},
	fab: {
		position: 'fixed',
		bottom: 16,
		right: 16,
		zIndex: 401
	},
	notFound: {
		position: 'fixed',
		alignSelf: 'center',
		height: '100%'
	},
	innerImageContainer: {
		display: 'flex',
		justifyContent: 'center',
		position: 'relative'
	}
});

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

/**
 * Obtain the closed days from the schedule object.
 * @param {Object} schedule - is the schedule object of the place.
 * @returns the closed days given the scheudle.
 */
const getClosedDays = (schedule) => {
	let closed = {};
	for (const day of days) {
		closed[day] = schedule[day]
			? schedule[day].opens === '-' || schedule[day].closes === '-'
			: false;
	}
	return closed;
};

const mapStateToProps = (state) => ({
	places: state.places
});

const mapDispatchToProps = (dispatch) => ({
	onUpdatePlaces: (place) => dispatch(updatePlaces(place))
});

class PlaceEdit extends PureComponent {
	constructor(props) {
		super(props);

		const { place, schedule, location } = props.placeData;

		const placeData = {
			place: isEmpty(place)
				? {
						name: '',
						description: '',
						tags: null
				  }
				: place,
			schedule: isEmpty(schedule)
				? {
						mon: { opens: '7 AM', closes: '7 PM' },
						tue: { opens: '7 AM', closes: '7 PM' },
						wed: { opens: '7 AM', closes: '7 PM' },
						thu: { opens: '7 AM', closes: '7 PM' },
						fri: { opens: '7 AM', closes: '7 PM' },
						sat: { opens: '7 AM', closes: '7 PM' },
						sun: { opens: '7 AM', closes: '7 PM' }
				  }
				: schedule,
			location: isEmpty(location)
				? {
						lat: 0,
						lng: 0
				  }
				: location
		};

		this.state = {
			requested: false,
			hasError: false,
			placeData,
			closed: getClosedDays(schedule),
			dialogOpen: false,
			photoLoading: false,
			usedTags: []
		};
	}

	componentWillMount = () => {
		const { auth, match } = this.props;
		const { requested } = this.state;
		if (!requested) {
			this.setState({ requested: true });
			getPlace(auth, match.params.placeId)
				.then((placeData) => {
					if (placeData) {
						this.setState({
							placeData,
							closed: getClosedDays(placeData.schedule)
						});
					} else {
						console.log(match.params.placeId);
						console.log(placeData);
						this.setState({ hasError: true });
					}
				})
				.catch((err) => {
					console.error(err);
					this.setState({ hasError: true });
				});
			getUsedTags$(this.props.auth).subscribe((usedTags) =>
				this.setState({ usedTags })
			);
		}
	};

	handleSave = (e) => {
		const { placeData } = this.state;
		const { place, schedule, location } = placeData;

		this.props.onUpdatePlaces(placeData);

		savePlace(this.props.auth, place, location, schedule)
			.then((r) => this.props.history.replace('/m/dashboard'))
			.catch((err) => console.error(err));
	};

	handleScheduleChange = (schedule) => {
		const sch = Object.assign({}, this.state.placeData.schedule, schedule);
		const { placeData } = this.state;
		this.setState({
			placeData: { ...placeData, schedule: sch }
		});
	};

	handleClosedChange = (day, isClosed) => {
		const { closed } = this.state;
		this.setState({ closed: { ...closed, [day.toLowerCase()]: isClosed } });
	};

	handleLocationChange = (location) => {
		const { placeData } = this.state;
		this.setState({ placeData: { ...placeData, location } });
	};

	handleTagsChange = (tags) => {
		const { placeData } = this.state;
		const { place } = placeData;

		this.setState({
			placeData: {
				...placeData,
				place: { ...place, tags }
			}
		});
	};

	render() {
		const { classes } = this.props;
		const {
			placeData,
			hasError,
			closed,
			dialogOpen,
			photoLoading,
			usedTags
		} = this.state;
		const { place, schedule, location } = placeData;

		return (
			<div className={classes.newPlaceContainer}>
				{!hasError && (
					<>
						<h2>Edit a place</h2>
						<TextField
							id="outlined-name"
							label="Name"
							placeholder="Name"
							className={classes.textField}
							value={place.name}
							onChange={(e) =>
								this.setState({
									placeData: {
										...placeData,
										place: { ...place, name: e.target.value }
									}
								})
							}
							margin="normal"
							variant="outline"
						/>
						<TextField
							id="outlined-description"
							label="Description"
							placeholder="Description"
							className={classes.textField}
							value={place.description}
							onChange={(e) =>
								this.setState({
									placeData: {
										...placeData,
										place: { ...place, description: e.target.value }
									}
								})
							}
							margin="normal"
							variant="outline"
						/>
						<h4>Tags</h4>
						{place.tags && (
							<ChipInput
								options={usedTags}
								values={place.tags.map((t) => ({ label: t, value: t }))}
								onChange={this.handleTagsChange}
							/>
						)}
						<h2>Select an image</h2>
						<div className={classes.imageContainer}>
							<div className={classes.innerImageContainer}>
								<ImagePlaceholder
									image={place.photo}
									onClick={() => this.setState({ dialogOpen: true })}
									loading={photoLoading}
									onLoaded={(e) => this.setState({ photoLoading: false })}
								/>
							</div>
						</div>
						<ScheduleForm
							schedule={schedule}
							closed={closed}
							onScheduleChange={this.handleScheduleChange}
							onClosedChange={this.handleClosedChange}
						/>
						<h3>Locate on map</h3>
						<div className={classes.mapContainer}>
							<PlaceMap
								placeLocation={{
									lat: location ? location.lat : 0,
									lng: location ? location.lng : 0
								}}
								onLocationChange={this.handleLocationChange}
							/>
						</div>
						<div className={classes.fab}>
							<Fab color="primary" aria-label="Save" onClick={this.handleSave}>
								<ContentSaveIcon />
							</Fab>
						</div>
					</>
				)}
				{hasError && (
					<div className={classes.notFound}>
						<NotFound
							title="Did not found that"
							subtitle="Please go back and select another option."
						/>
					</div>
				)}
				<ImageSelectDialog
					open={dialogOpen}
					onSelect={(photo) =>
						this.setState({
							placeData: { ...placeData, place: { ...place, photo } },
							dialogOpen: false,
							photoLoading: true
						})
					}
					onClose={() => this.setState({ dialogOpen: false })}
				/>
			</div>
		);
	}
}

PlaceEdit.propTypes = {
	placeData: PropTypes.object
};

PlaceEdit.defaultProps = {
	placeData: {
		place: {
			name: '',
			description: ''
		},
		schedule: {
			mon: { opens: '7 AM', closes: '7 PM' },
			tue: { opens: '7 AM', closes: '7 PM' },
			wed: { opens: '7 AM', closes: '7 PM' },
			thu: { opens: '7 AM', closes: '7 PM' },
			fri: { opens: '7 AM', closes: '7 PM' },
			sat: { opens: '7 AM', closes: '7 PM' },
			sun: { opens: '7 AM', closes: '7 PM' }
		},
		location: {
			lat: 0,
			lng: 0
		}
	}
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(PlaceEdit));
