export const SHARED_PLACES_ADD = 'SHARED_PLACES_ADD';
export const SHARED_PLACES_BULK_ADD = 'SHARED_PLACES_BULK_ADD';
export const SHARED_PLACES_BULK_UPDATE = 'SHARED_PLACES_BULK_UPDATE';
export const SHARED_PLACES_REMOVE = 'SHARED_PLACES_REMOVE';
export const SHARED_PLACES_UPDATE = 'SHARED_PLACES_UPDATE';
export const SHARED_PLACES_COMPLETE_ADD = 'SHARED_PLACES_COMPLETE_ADD';

export const addSharedPlaces = (payload) => ({
	type: SHARED_PLACES_ADD,
	payload
});

export const addBulkSharedPlaces = (payload) => ({
	type: SHARED_PLACES_BULK_ADD,
	payload
});

export const removeSharedPlaces = (payload) => ({
	type: SHARED_PLACES_REMOVE,
	payload
});

export const updateSharedPlaces = (payload) => ({
	type: SHARED_PLACES_UPDATE,
	payload
});

export const updateBulkSharedPlaces = (payload) => ({
	type: SHARED_PLACES_BULK_UPDATE,
	payload
});

export const completeAddSharedPlaces = (payload) => ({
	type: SHARED_PLACES_COMPLETE_ADD,
	payload
});
