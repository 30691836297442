import React from 'react';
import PropTypes from 'prop-types';
import history from '../../utils/history';
import Fab from '../ui/Fab';
import PlusIcon from 'mdi-react/PlusIcon';
import { makeStyles } from '@material-ui/styles';
import Button from '../ui/Button';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexFlow: 'column',
		height: '100%',
		width: '100%'
	},
	icon: {
		fontSize: 60
	},
	buttonContainer: {
		display: 'flex'
	},
	button: {
		margin: '0 8px'
	},
	title: { margin: 16 },
	subtitle: { margin: 32 }
});

const Login = ({ auth }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<h1 className={classes.title}>Schedule your favourite places.</h1>
			{!auth.isAuthenticated() && (
				<>
					<h3 className={classes.subtitle}>
						This tool needs you to be authenticated.
					</h3>
					<div className={classes.buttonContainer}>
						<Button
							className={classes.button}
							variant="outlined"
							color="info"
							onClick={() => history.push(`/m/login`)}>
							Login
						</Button>
						<Button
							className={classes.button}
							variant="normal"
							color="info"
							onClick={() => history.push(`/m/register`)}>
							Create account
						</Button>
					</div>
				</>
			)}
			{auth.isAuthenticated() && auth.userProfile && (
				<>
					<h3 className={classes.subtitle}>
						Welcome, {auth.userProfile.name}!
					</h3>
					<Fab onClick={() => history.push('/m/place')}>
						<PlusIcon />
						ADD PLACE
					</Fab>
				</>
			)}
		</div>
	);
};

Login.propTypes = {
	auth: PropTypes.object.isRequired
};

export default Login;
