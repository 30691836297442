import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		width: '100%',
		height: 56,
		alignItems: 'center'
	}
});

const AppBar = (props) => {
	const c = useStyles(props);
	return <div className={c.root}>{props.children}</div>;
};

export default AppBar;
