import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PlaceMap from '../../containers/PlaceMap/PlaceMap';
import { isEmpty } from '../../utils/utils';
import { removePlace, unsharePlace$ } from '../../sandboxes/Sandbox';
import { connect } from 'react-redux';
import {
	getFormattedDayOfWeek,
	isNowBetweenHours
} from '../../utils/text.utils';
import Popover from 'react-tiny-popover';

import { removePlaces } from '../../store/actions/Places.actions';

import Button from '../ui/Button';
import Card from '../ui/Card';
import Chip from '../ui/Chip';
import Fab from '../ui/Fab';
import Row from '../ui/Row';
import { makeStyles } from '@material-ui/styles';
import history from '../../utils/history';
import PencilIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import InformationIcon from 'mdi-react/InformationIcon';
import UndoVariantIcon from 'mdi-react/UndoVariantIcon';
import MenuItem from '../ui/MenuItem';

const dayNames = {
	mon: 'Monday',
	tue: 'Tuesday',
	wed: 'Wednesday',
	thu: 'Thursday',
	fri: 'Friday',
	sat: 'Saturday',
	sun: 'Sunday'
};

const useTableStyles = makeStyles({
	cell: {
		margin: '8px 12px',
		flexGrow: 1,
		width: '100%'
	}
});

const ScheduleTableRow = ({ classes, day, time, selected }) => {
	const c = useTableStyles();
	if (time.opens === '-' || time.closes === '-') {
		return (
			<Row style={{ backgroundColor: selected ? '#FFEB3B' : '#fff' }}>
				<div>
					<b>{dayNames[day]}</b>
				</div>
				<div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<InformationIcon />
						<span>Closed</span>
					</div>
				</div>
			</Row>
		);
	}
	return (
		<Row style={{ backgroundColor: selected ? '#FFEB3B' : '#fff' }}>
			<div className={c.cell}>
				<b>{dayNames[day]}</b>
			</div>
			<div className={c.cell}>{time.opens}</div>
			<div className={c.cell}>{time.closes}</div>
		</Row>
	);
};

const ScheduleTable = ({ classes, schedule }) => {
	const c = useTableStyles();
	const day = getFormattedDayOfWeek();
	return (
		<Card className={classes.table}>
			<Row>
				<div className={c.cell}>Day</div>
				<div className={c.cell}>Opens</div>
				<div className={c.cell}>Closses</div>
			</Row>
			{Object.keys(schedule).map((s) => (
				<ScheduleTableRow
					key={s}
					classes={classes}
					day={s}
					time={schedule[s]}
					selected={s === day}
				/>
			))}
		</Card>
	);
};

const useStyles = makeStyles({
	imageContainer: {
		width: '100%',
		height: 200,
		position: 'relative'
	},
	image: {
		objectFit: 'none',
		width: '100%',
		height: 200
	},
	infoContainer: {
		padding: 16
	},
	mapContainer: {
		width: '100%',
		height: 200
	},
	button: {
		margin: '0 8px'
	},
	error: {
		color: '#fff',
		backgroundColor: '#F44336',
		'&:hover': {
			backgroundColor: '#F44336'
		}
	},
	scheduleRow: {
		display: 'flex',
		margin: 8
	},
	scheduleCell: {
		flex: 1
	},
	textCentered: {
		textAlign: 'center'
	},
	table: {
		width: 'auto',
		marginTop: 24
	},
	fab: {
		right: 32,
		zIndex: 401,
		position: 'absolute',
		margin: '-32px 0px'
	},
	chip: {
		margin: '8px 4px'
	},
	chipContainer: {
		display: 'flex'
	},
	schedule: {
		margin: 'auto 8px'
	}
});

const mapStateToProps = (state) => ({
	places: state.places
});

const mapDispatchToProps = (dispatch) => ({
	onRemovePlace: (place) => dispatch(removePlaces(place))
});

const PlaceDetails = ({
	auth,
	placeData,
	onRemovePlace,
	onDelete,
	onClose
}) => {
	const { id, place, schedule, location } = placeData;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [sharedAnchorEl, setSharedAnchorEl] = useState(null);

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const isOpen = useMemo(
		() =>
			isNowBetweenHours(
				new Date(),
				schedule[getFormattedDayOfWeek()].opens,
				schedule[getFormattedDayOfWeek()].closes
			),
		[schedule]
	);

	const handlePlaceDelete = async () => {
		setAnchorEl(null);
		try {
			await removePlace(auth, id);
			onRemovePlace(place);
			if (onDelete) {
				onDelete(place);
			} else {
				history.replace('/m/dashboard');
			}
		} catch (err) {
			console.error(err);
		}
	};

	const handlePlaceUnshare = () => {
		unsharePlace$(auth, id).subscribe((res) => {
			setSharedAnchorEl(null);
			onClose();
		});
	};

	return (
		<>
			<div className={classes.imageContainer}>
				<img className={classes.image} src={place.photo} alt={place.photo} />
				{!isEmpty(place) && placeData.owner && (
					<div className={classes.fab}>
						<Fab color="primary" aria-label="Edit">
							<PencilIcon />
						</Fab>
					</div>
				)}
			</div>
			<div className={classes.infoContainer}>
				<h5>{place.name}</h5>
				<div className={classes.chipContainer}>
					<Chip
						className={classes.chip}
						title={isOpen ? 'Open now' : 'Closed now'}
						color="#2046C4"
					/>
					<span className={classes.schedule}>
						{schedule[getFormattedDayOfWeek()].opens} -{' '}
						{schedule[getFormattedDayOfWeek()].closes}
					</span>
				</div>
				<p>{place.description}</p>
				{place.tags &&
					place.tags.length > 0 &&
					place.tags.map((t) => (
						<Chip key={t} title={t} className={classes.chip} />
					))}
				<div>
					{placeData.owner && (
						<Popover
							isOpen={Boolean(anchorEl)}
							position={'bottom'} // preferred position
							containerStyle={{
								zIndex: 1200,
								padding: '24px',
								paddingTop: '0px'
							}}
							onClickOutside={() => setAnchorEl(null)}
							content={
								<Card style={{ display: 'flex', flexDirection: 'column' }}>
									<MenuItem
										onClick={handlePlaceDelete}
										style={{ color: 'red', fontWeight: 'bold' }}>
										Confirm deletion
									</MenuItem>
									<MenuItem onClick={handleMenuClose}>Cancel</MenuItem>
								</Card>
							}>
							<Button
								className={classnames(classes.button, classes.error)}
								variant="normal"
								color="danger"
								onClick={(e) => setAnchorEl(e.currentTarget)}>
								<DeleteIcon />
								Delete
							</Button>
						</Popover>
					)}
					{!placeData.owner && (
						<Popover
							isOpen={Boolean(sharedAnchorEl)}
							position={'bottom'} // preferred position
							onClickOutside={() => setSharedAnchorEl(null)}
							content={
								<Card>
									<MenuItem
										onClick={handlePlaceUnshare}
										style={{ color: 'red', fontWeight: 'bold' }}>
										Confirm unshare place
									</MenuItem>
									<MenuItem onClick={handleMenuClose}>Cancel</MenuItem>
								</Card>
							}>
							<Button
								className={classes.button}
								variant="contained"
								onClick={(e) => setSharedAnchorEl(e.currentTarget)}>
								<UndoVariantIcon />
								Unshare
							</Button>
						</Popover>
					)}
				</div>
				<ScheduleTable classes={classes} schedule={schedule} />
			</div>
			<div className={classes.mapContainer}>
				<PlaceMap
					disableZoomChange
					disableLocationChange
					disable
					placeLocation={{
						lat: location.lat,
						lng: location.lng
					}}
				/>
			</div>
		</>
	);
};

PlaceDetails.propTypes = {
	placeData: PropTypes.object.isRequired
};

PlaceDetails.defaultProps = {
	onClose: () => {}
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PlaceDetails);
