import React from 'react';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Chip from '../ui/Chip';
import MenuItem from '../ui/MenuItem';
import Card from '../ui/Card';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		height: 250
	},
	input: {
		display: 'flex',
		padding: 0,
		height: 'auto'
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden'
	},

	noOptionsMessage: {
		padding: '8px 16px'
	},
	singleValue: {
		fontSize: 16
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		top: 0,
		bottom: 6,
		fontSize: 16
	},
	paper: {
		position: 'absolute',
		zIndex: 1,
		marginTop: 8,
		left: 0,
		right: 0
	},
	divider: {
		height: 16
	}
});

function NoOptionsMessage(props) {
	return (
		<h4
			className={props.selectProps.classes.noOptionsMessage}
			{...props.innerProps}>
			{props.children}
		</h4>
	);
}

NoOptionsMessage.propTypes = {
	children: PropTypes.node,
	innerProps: PropTypes.object,
	selectProps: PropTypes.object.isRequired
};

function inputComponent({ inputRef, ...props }) {
	return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
	inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

const useControlStyles = makeStyles({
	root: {
		border: '1px solid #dfdfdf',
		borderRadius: 8,
		display: 'flex'
	}
});

const Control = (props) => {
	const c = useControlStyles();
	return (
		<div
			className={clsx(c.root, props.selectProps.classes.input)}
			ref={props.innerRef}
			{...props.innerProps}>
			{props.children}
		</div>
	);
};

Control.propTypes = {
	children: PropTypes.node,
	innerProps: PropTypes.object,
	innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	selectProps: PropTypes.object.isRequired
};

function Option(props) {
	return (
		<MenuItem
			ref={props.innerRef}
			selected={props.isFocused}
			style={{
				fontWeight: props.isSelected ? 500 : 400
			}}
			{...props.innerProps}>
			{props.children}
		</MenuItem>
	);
}

Option.propTypes = {
	children: PropTypes.node,
	innerProps: PropTypes.object,
	innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	isFocused: PropTypes.bool,
	isSelected: PropTypes.bool
};

function Placeholder(props) {
	return (
		<h2 className={props.selectProps.classes.placeholder} {...props.innerProps}>
			{props.children}
		</h2>
	);
}

Placeholder.propTypes = {
	children: PropTypes.node,
	innerProps: PropTypes.object,
	selectProps: PropTypes.object.isRequired
};

function SingleValue(props) {
	return (
		<h3 className={props.selectProps.classes.singleValue} {...props.innerProps}>
			{props.children}
		</h3>
	);
}

SingleValue.propTypes = {
	children: PropTypes.node,
	innerProps: PropTypes.object,
	selectProps: PropTypes.object.isRequired
};

function ValueContainer(props) {
	return (
		<div className={props.selectProps.classes.valueContainer}>
			{props.children}
		</div>
	);
}

ValueContainer.propTypes = {
	children: PropTypes.node,
	selectProps: PropTypes.object.isRequired
};

function MultiValue(props) {
	return (
		<Chip
			tabIndex={-1}
			title={props.children}
			focused={props.isFocused}
			onClose={props.removeProps.onClick}
		/>
	);
}

MultiValue.propTypes = {
	children: PropTypes.node,
	isFocused: PropTypes.bool,
	removeProps: PropTypes.object.isRequired,
	selectProps: PropTypes.object.isRequired
};

function Menu(props) {
	return (
		<Card className={props.selectProps.classes.paper} {...props.innerProps}>
			{props.children}
		</Card>
	);
}

Menu.propTypes = {
	children: PropTypes.node,
	innerProps: PropTypes.object,
	selectProps: PropTypes.object
};

const components = {
	Control,
	Menu,
	MultiValue,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer
};

const ChipInput = ({ options, values, onChange }) => {
	const classes = useStyles();

	const handleChange = (tags) => {
		onChange(tags ? tags.map((t) => t.value) : []);
	};

	const selectStyles = {
		input: (base) => ({
			...base,
			color: '#a1a1a1',
			'& input': {
				font: 'inherit'
			}
		})
	};

	return (
		<CreatableSelect
			classes={classes}
			styles={selectStyles}
			isMulti
			defaultValue={values}
			onChange={handleChange}
			options={options.map((o) => ({ label: o, value: o }))}
			components={components}
		/>
	);
};

ChipInput.propTypes = {
	options: PropTypes.array,
	values: PropTypes.array,
	onChange: PropTypes.func
};

ChipInput.defaultProps = {
	options: [],
	values: [],
	onChange: () => {}
};

export default ChipInput;
