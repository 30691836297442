import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	root: ({ variant }) => {
		if (variant === 'clean') {
			return {
				border: '0px solid #bdbdbd',
				padding: 8,
				fontSize: '1rem',
				margin: 8,
				backgroundColor: '#ffffff'
			};
		} else {
			return {
				border: '1px solid #bdbdbd',
				borderRadius: 4,
				padding: 8,
				fontSize: '1rem',
				margin: 8,
				backgroundColor: '#ffffff'
			};
		}
	}
});

const TextField = ({ inputRef, className, ...rest }) => {
	const c = useStyles(rest);
	return <input className={clsx(c.root, className)} ref={inputRef} {...rest} />;
};

TextField.propTypes = {
	inputRef: PropTypes.object,
	variant: PropTypes.oneOf(['normal', 'clean'])
};

TextField.defaultProps = {
	variant: 'normal'
};

export default TextField;
