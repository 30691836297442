import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { getColor, getBackgroundImage, validateColor } from '../utils/colors';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: ({ color, variant, disabled, size }) => ({
		backgroundColor:
			variant === 'outlined'
				? disabled
					? '#eaeaea'
					: 'transparent'
				: variant === 'transparent'
				? 'transparent'
				: getColor(color),
		backgroundImage:
			variant === 'outlined' || variant === 'transparent'
				? 'transparent'
				: getBackgroundImage(color),
		height: 56,
		width: 56,
		border:
			variant === 'outlined' ? `1px solid ${getColor(color)}` : '0 solid #000',
		borderRadius: '100%',
		cursor: 'pointer',
		color:
			variant === 'outlined' || variant === 'transparent'
				? getColor(color)
				: '#fff',
		fontSize: 15,
		boxShadow:
			variant === 'outlined' || variant === 'transparent'
				? 'unset'
				: '0 15px 30px -5px rgba(0, 0, 0, 0.25)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		transition:
			'box-shadow 0.3s ease, transform 0.3s ease, filter 0.3s ease, background-color 0.3s ease',
		'&:hover': {
			boxShadow:
				variant === 'outlined' || variant === 'transparent'
					? 'unset'
					: '0 15px 30px -12px rgba(0, 0, 0, 0.50)',
			backgroundColor:
				variant === 'outlined' || variant === 'transparent'
					? 'rgba(0, 0, 0, 0.125)'
					: getColor(color)
		},
		'&:focus': {
			outline: 'unset',
			backgroundColor:
				variant === 'outlined' || variant === 'transparent'
					? '#eaeaea'
					: getColor(color)
		},
		'&:active': {
			filter: disabled ? '' : 'brightness(1.2)'
		}
	})
});

const IconButton = ({ children, className, onClick, ...rest }) => {
	const c = useStyles(rest);
	return (
		<button className={clsx(c.root, className)} onClick={onClick} {...rest}>
			{children}
		</button>
	);
};

IconButton.propTypes = {
	onClick: PropTypes.func,
	color: validateColor,
	variant: PropTypes.oneOf(['outlined', 'normal', 'transparent']),
	disabled: PropTypes.bool,
	size: PropTypes.oneOf(['small', 'mini', 'large', 'normal'])
};

IconButton.defaultProps = {
	onClick: () => {},
	color: 'info',
	variant: 'normal',
	disabled: false,
	size: 'normal'
};

export default IconButton;
