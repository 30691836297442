import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { searchUsers$ } from '../../../sandboxes/Sandbox';
import { BehaviorSubject } from 'rxjs';
import {
	debounceTime,
	filter,
	distinctUntilChanged,
	switchMap
} from 'rxjs/operators';
import IconButton from '../../ui/IconButton';
import Modal from '../../ui/Modal/Modal';
import TextField from '../../ui/TextField';

import ListItem from '../../ui/ListItem';
import DeleteIcon from 'mdi-react/DeleteIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';

const useStyles = makeStyles((theme) => ({
	searchBar: {
		display: 'flex',
		padding: '0 8px'
	},
	input: {
		flex: '1 1 auto',
		marginLeft: 8
	},
	subtitle: {
		marginLeft: 8
	}
}));

const query$ = new BehaviorSubject(null).pipe(
	debounceTime(300),
	filter((q) => q !== null && q.length > 0),
	distinctUntilChanged()
);

const UserComponent = ({ user, onClick }) => {
	return (
		<ListItem
			button
			onClick={() => onClick(user.value)}
			icon={user.actual ? '' : null}
			primary={user.label}
		/>
	);
};

const SharePlaceDialog = ({
	open,
	placeId,
	sharedWith,
	onClose,
	onSelect,
	auth,
	onUnshare
}) => {
	const classes = useStyles();
	const [users, setUsers] = useState([]);
	const inputRef = useRef(null);

	useEffect(() => {
		const subs = query$
			.pipe(switchMap((q) => searchUsers$(auth, q)))
			.subscribe((users) => {
				setUsers(
					users.map((u) => ({ ...u, actual: sharedWith.indexOf(u.name) >= 0 }))
				);
			});
		return () => {
			subs.unsubscribe();
		};
	}, [auth, sharedWith]);

	const handleSearchClick = (e) => {
		query$.next(inputRef.current.value);
	};

	return (
		<Modal visible={open} onHide={() => onClose()}>
			<h2 className={classes.subtitle}>Select a user</h2>
			<div className={classes.searchBar}>
				<TextField
					inputRef={inputRef}
					variant="clean"
					onChange={(e) => query$.next(e.target.value)}
					className={classes.input}
					placeholder="Search by keyword"
				/>
			</div>
			<div>
				{users.map((user) => (
					<UserComponent
						key={user.value}
						user={user}
						onClick={(u) => onSelect(u, placeId)}
					/>
				))}
			</div>
			{sharedWith && sharedWith.length > 0 && (
				<>
					<h3 className={classes.subtitle}>Already shared</h3>
					<div>
						{sharedWith.map((user) => (
							<ListItem
								key={user}
								primary={user}
								action={
									<IconButton
										variant="transparent"
										aria-label="Delete"
										onClick={() => onUnshare(user, placeId)}
										color="danger">
										<DeleteIcon />
									</IconButton>
								}
							/>
						))}
					</div>
				</>
			)}
		</Modal>
	);
};

SharePlaceDialog.propTypes = {
	placeId: PropTypes.string,
	onClose: PropTypes.func,
	onSelect: PropTypes.func,
	onUnshare: PropTypes.func
};

SharePlaceDialog.defaultProps = {
	onClose: () => {},
	onSelect: () => {},
	onUnshare: () => {}
};

export default SharePlaceDialog;
