import React, { PureComponent, Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import NewPlace from './../NewPlace/index';
import Loading from '../../components/ui/Loading';
import AppLayout from '../../components/AppLayout';
import Dashboard from '../Dashboard';
import ImagesSearch from '../ImagesSearch';
import Login from '../../components/Login/Login';
import Settings from '../../components/Settings/Settings';
import SharedPlaces from '../../components/SharedPlaces';
import LoginPage from '../../components/LoginPage';
import RegisterPage from '../../components/RegisterPage';

const styles = () => ({
	content: {
		padding: '0 16px 16px 16px'
	},
	toolbar: {
		marginTop: 64
	}
});

class Main extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			anchorEl: null
		};
	}

	componentWillMount = () => {
		this.props.auth.validateSession$().subscribe((profile) => {
			this.setState({ username: profile.name });
		});
	};

	render() {
		const { classes, match, auth, history } = this.props;

		return (
			<Suspense fallback={<Loading size={3} />}>
				<AppLayout auth={auth} history={history}>
					<Switch>
						<Route
							path={`${match.path}`}
							exact
							render={(props) => <Login auth={auth} />}
						/>
						<Route
							path={`${match.path}/place`}
							render={(props) => (
								<div className={classes.route}>
									<NewPlace
										from={match.path}
										{...props}
										auth={auth}
										onMount={this.onComponentMount}
									/>
								</div>
							)}
						/>
						<Route
							path={`${match.path}/dashboard`}
							render={(props) => (
								<div className={classes.route}>
									<Dashboard from={match.path} auth={auth} {...props} />
								</div>
							)}
						/>
						<Route
							path={`${match.path}/shared`}
							render={(props) => (
								<div className={classes.route}>
									<SharedPlaces from={match.path} auth={auth} {...props} />
								</div>
							)}
						/>
						<Route
							path={`${match.path}/images`}
							render={(props) => (
								<div className={classes.route}>
									<ImagesSearch from={match.path} auth={auth} {...props} />
								</div>
							)}
						/>
						<Route
							path={`${match.path}/settings`}
							render={(props) => (
								<div className={classes.route}>
									<Settings from={match.path} auth={auth} {...props} />
								</div>
							)}
						/>
						<Route
							path={`${match.path}/login`}
							render={(props) => (
								<div className={classes.route}>
									<LoginPage from={match.path} auth={auth} {...props} />
								</div>
							)}
						/>
						<Route
							path={`${match.path}/register`}
							render={(props) => (
								<div className={classes.route}>
									<RegisterPage from={match.path} auth={auth} {...props} />
								</div>
							)}
						/>
						<Route render={() => <Redirect to="/404" />} />
					</Switch>
				</AppLayout>
			</Suspense>
		);
	}
}

export default withStyles(styles)(Main);
