import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from 'use-media-query-hook';

const getFromMediaQuery = (layout) => {
	switch (layout) {
		case 'mobile':
			return '(min-width: 1px)';
		case 'tablet':
			return '(min-width: 401px)';
		case 'desktop':
			return '(min-width: 641px)';
		case 'large-desktop':
			return '(min-width: 1025px)';
		default:
			return '(min-width: 1px)';
	}
};

const getBelowMediaQuery = (layout) => {
	switch (layout) {
		case 'mobile':
			return '(max-width: 400px)';
		case 'tablet':
			return '(max-width: 640px)';
		case 'desktop':
			return '(max-width: 1024px)';
		case 'large-desktop':
			return '(max-width: 8096px)';
		default:
			return '(max-width: 400px)';
	}
};

const getOnlyMediaQuery = (layout) => {
	switch (layout) {
		case 'mobile':
			return '(max-width: 400px)';
		case 'tablet':
			return '(min-width: 401px) and (max-width: 640px)';
		case 'desktop':
			return '(min-width: 641px) and (max-width: 1024px)';
		case 'large-desktop':
			return '(min-width: 1025px)';
		default:
			return '(max-width: 400px)';
	}
};

const Hidden = ({ children, showFrom, showBelow, showOnly, ...rest }) => {
	let mediaQuery = '';
	if (showFrom) {
		mediaQuery = getFromMediaQuery(showFrom);
	} else if (showBelow) {
		mediaQuery = getBelowMediaQuery(showBelow);
	} else {
		mediaQuery = getOnlyMediaQuery(showOnly);
	}
	const willShow = useMediaQuery(mediaQuery);
	return <>{willShow ? children : null}</>;
};
Hidden.propTypes = {
	showFrom: PropTypes.oneOf(['mobile', 'tablet', 'desktop', 'large-desktop']),
	showOnly: PropTypes.oneOf(['mobile', 'tablet', 'desktop', 'large-desktop']),
	showBelow: PropTypes.oneOf(['mobile', 'tablet', 'desktop', 'large-desktop'])
};

Hidden.defaultProps = {
	// bla: 'test',
};

export default Hidden;
