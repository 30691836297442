import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		justifyContent: 'center',
		minHeight: 48
	},
	clickableContainer: ({ button }) => ({
		cursor: button ? 'pointer' : 'default',
		flexGrow: 1,
		alignItems: 'center',
		display: 'flex',
		paddingLeft: 12,
		transition: 'background-color 0.3s ease',
		'&:hover': {
			backgroundColor: button ? '#eaeaea' : '#fff'
		}
	}),
	listItem: {
		display: 'flex',
		alignItems: 'center',
		padding: 12,
		cursor: 'pointer',
		transition: 'background-color 0.3s ease',
		'&:hover': {
			backgroundColor: '#eaeaea'
		}
	},
	listIcon: {
		marginRight: 16
	},
	listItemText: {
		display: 'flex',
		flexDirection: 'column'
	},
	listItemTextPrimary: {
		fontWeight: 'bold'
	},
	listItemTextSecondary: {},
	listAction: {}
});

const ListItem = ({
	children,
	primary,
	secondary,
	className,
	icon,
	action,
	onClick,
	...rest
}) => {
	const c = useStyles(rest);
	return (
		<div className={clsx(c.root, className)} {...rest}>
			<div className={c.clickableContainer} onClick={onClick}>
				{icon && <div className={c.listIcon}>{icon}</div>}
				<span className={c.listItemTextPrimary}>{primary}</span>
				{secondary && (
					<span className={c.listItemTextSecondary}>{secondary}</span>
				)}
			</div>
			{action && action}
		</div>
	);
};

ListItem.propTypes = {
	primary: PropTypes.string,
	secondary: PropTypes.string,
	action: PropTypes.func,
	button: PropTypes.bool
};

ListItem.defaultProps = {};

export default ListItem;
