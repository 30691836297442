import { places } from './Places.reducers';
import { sharedPlaces } from './SharedPlaces.reducers';
import { resources } from './Resources.reducers';
import { combineReducers } from 'redux';

const app = combineReducers({
	places,
	sharedPlaces,
	resources
});

export default app;
