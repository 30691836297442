import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ajax } from 'rxjs/ajax';
import { map, filter } from 'rxjs/operators';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 16,
		display: 'inline-flex'
	},
	image: {
		borderRadius: 8,
		objectFit: 'cover',
		boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
		transition: '0.3s',
		'&:hover': {
			boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
		},
		cursor: 'pointer'
	},
	placeholder: {
		position: 'absolute'
	}
}));

const ImageItem = ({ src, onSelected, width, height }) => {
	const [loading, setLoading] = useState(true);
	const [imageUrl, setImageUrl] = useState(null);
	const classes = useStyles();
	const subsRef = useRef(null);

	useEffect(() => {
		const obs = ajax(src).pipe(
			filter((res) => res !== null || res !== undefined),
			map((res) => res.xhr.responseURL)
		);
		subsRef.current = obs.subscribe((url) => {
			setImageUrl(url);
			subsRef.current.unsubscribe();
		});
	}, [src]);

	// Component will unmount
	useEffect(() => {
		return () => {
			// Unsubscribe to current subscription
			subsRef.current.unsubscribe();
		};
	}, []);

	return (
		<div className={classes.root} style={{ width, height, minHeight: height }}>
			{imageUrl && (
				<img
					style={{ width, height }}
					onClick={() => {
						onSelected(imageUrl);
					}}
					onLoad={() => setLoading(false)}
					className={classes.image}
					src={src}
					alt={src}
				/>
			)}
			{loading && (
				<div
					className="ph-item"
					style={{ width, height, position: 'absolute' }}>
					<div className="ph-col-12">
						<div className="ph-picture" />
					</div>
				</div>
			)}
		</div>
	);
};

ImageItem.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number
};

ImageItem.defaulProps = {
	width: 300,
	height: 300
};

export default ImageItem;
