import history from '../../utils/history';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { loginUser$, getUserProfile$ } from '../../sandboxes/Sandbox';

export default class Auth {
	accessToken;
	idToken;
	expiresAt;
	userProfile;

	constructor() {
		// this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
		// this.handleAuthentication = this.handleAuthentication.bind(this);
		this.isAuthenticated = this.isAuthenticated.bind(this);
		this.getAccessToken = this.getAccessToken.bind(this);
		this.getIdToken = this.getIdToken.bind(this);
		this.renewSession = this.renewSession.bind(this);
	}

	login$(email, password) {
		// this.auth0.authorize();
		return loginUser$(email, password).pipe(
			tap((token) => {
				this.setSession(token);
			})
		);
	}

	// handleAuthentication() {
	// 	this.auth0.parseHash((err, authResult) => {
	// 		if (authResult && authResult.accessToken && authResult.idToken) {
	// 			this.setSession(authResult);
	// 			this.getUserInfo(authResult);
	// 			this.userProfile = authResult.userProfile;
	// 		} else if (err) {
	// 			history.push('/m');
	// 			console.log(err);
	// 		}
	// 	});
	// }

	getAccessToken() {
		return this.accessToken;
	}

	getIdToken() {
		return this.idToken;
	}

	setAuthSessionData() {
		this.accessToken = localStorage.getItem('accessToken');
		this.idToken = localStorage.getItem('idToken');
		this.expiresAt = parseInt(localStorage.getItem('expiresAt'));
	}

	setSession(authResult) {
		// Set the time that the access token will expire at
		let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
		this.accessToken = authResult.accessToken;
		this.idToken = authResult.idToken;
		this.expiresAt = expiresAt;
		this.userProfile = authResult.userProfile;
		// Persist login information
		localStorage.setItem('accessToken', this.accessToken);
		localStorage.setItem('idToken', this.idToken);
		localStorage.setItem('expiresAt', this.expiresAt);

		// navigate to the home route
		history.push('/m');
	}

	renewSession() {
		this.auth0.checkSession({}, (err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				this.setSession(authResult);
			} else if (err) {
				this.logout();
				console.log(err);
				alert(
					`Could not get a new token (${err.error}: ${err.error_description}).`
				);
			}
		});
	}

	getSession() {
		if (this.isAuthenticated()) {
			this.accessToken = localStorage.getItem('accessToken');
			this.idToken = localStorage.getItem('idToken');
			this.expiresAt = parseInt(localStorage.getItem('expiresAt'));
		}
	}

	validateSession() {
		this.setAuthSessionData();
		return this.getUserInfo();
	}

	validateSession$() {
		this.setAuthSessionData();
		return this.getUserInfo$();
	}

	async getUserInfo() {
		let token;
		let expiresAt = localStorage.getItem('expiresAt');
		if (expiresAt && parseInt(expiresAt) > new Date().getTime()) {
			token = localStorage.getItem('accessToken');
		}
		return new Promise((resolve, reject) => {
			if (!token) {
				reject();
				return;
			}
			let sessionProfile = sessionStorage.getItem('lifeTrackProfile');
			if (sessionProfile && JSON.parse(sessionProfile)) {
				this.userProfile = JSON.parse(sessionProfile);
				resolve(this.userProfile);
				return;
			} else {
				reject();
				return;
			}
		});
	}

	getUserInfo$() {
		return new Observable((obs) => {
			let token;
			let expiresAt = localStorage.getItem('expiresAt');
			if (expiresAt && parseInt(expiresAt) > new Date().getTime()) {
				token = localStorage.getItem('accessToken');
			}
			if (!token) {
				obs.next({});
				return;
			}
			let sessionProfile = sessionStorage.getItem('lifeTrackProfile');
			if (sessionProfile && JSON.parse(sessionProfile)) {
				this.userProfile = JSON.parse(sessionProfile);
				obs.next(this.userProfile);
			} else {
				getUserProfile$({ getAccessToken: () => token }).subscribe(
					(profile) => {
						this.userProfile = profile;
						obs.next(profile);
					}
				);
			}
		});
	}

	logout() {
		// Remove tokens and expiry time
		this.accessToken = null;
		this.idToken = null;
		this.expiresAt = 0;

		// Remove localStorage elements
		localStorage.removeItem('accessToken');
		localStorage.removeItem('idToken');
		localStorage.removeItem('expiresAt');

		// navigate to the home route
		history.replace('/');
	}

	isAuthenticated() {
		// Check whether the current time is past the
		// access token's expiry time
		let expiresAt = this.expiresAt;
		return new Date().getTime() < expiresAt;
	}
}
