import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: {
		padding: '12px 16px',
		backgroundColor: '#fff',
		cursor: 'pointer',
		transition: 'background-color 0.3 ease',
		'&:hover': {
			backgroundColor: '#dfdfdf'
		},
		'&:first-child': {
			borderTopLeftRadius: 6,
			borderTopRightRadius: 6
		},
		'&:last-child': {
			borderBottomLeftRadius: 6,
			borderBottomRightRadius: 6
		}
	}
});

const MenuItem = ({ className, children, ...rest }) => {
	const c = useStyles(rest);
	return (
		<div className={clsx(c.root, className)} {...rest}>
			{children}
		</div>
	);
};

export default MenuItem;
