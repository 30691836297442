import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { registerUser$ } from '../../sandboxes/Sandbox';
import { switchMap } from 'rxjs/operators';
import TextField from '../ui/TextField';
import Button from '../ui/Button';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexFlow: 'column',
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center'
	},
	inputLabel: {
		flexGrow: 1
	},
	input: {
		padding: 8,
		margin: 8
	},
	formContainer: {
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'right'
	},
	form: {
		display: 'flex'
	}
});

const RegisterPage = ({ auth }) => {
	const classes = useStyles();
	const emailRef = useRef(null);
	const usernameRef = useRef(null);
	const passwordRef = useRef(null);

	const onGoClick = () => {
		registerUser$(
			emailRef.current.value,
			usernameRef.current.value,
			passwordRef.current.value
		)
			.pipe(
				// Now we proceed to automatically log in user
				switchMap((res) => {
					console.log(res);
					return auth.login$(emailRef.current.value, passwordRef.current.value);
				})
			)
			.subscribe((res) => {
				console.log(res);
			});
	};

	return (
		<div className={classes.root}>
			<h2>Create a new account</h2>
			<div className={classes.formContainer}>
				<div className={classes.form}>
					<h4 className={classes.inputLabel}>Username</h4>
					<TextField
						className={classes.input}
						inputRef={usernameRef}
						type="text"
					/>
				</div>
				<div className={classes.form}>
					<h4 className={classes.inputLabel}>Email</h4>
					<TextField
						className={classes.input}
						inputRef={emailRef}
						type="text"
					/>
				</div>
				<div className={classes.form}>
					<h4 className={classes.inputLabel}>Password</h4>
					<TextField
						className={classes.input}
						inputRef={passwordRef}
						type="text"
					/>
				</div>
			</div>
			<Button className={classes.button} onClick={onGoClick} title="Go" />
		</div>
	);
};

export default RegisterPage;
