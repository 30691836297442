import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: ({ active }) => ({
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: active ? 'white' : 'rgba(204,204,204,1)',
		padding: '8px 0px',
		flexGrow: 1,
		backgroundColor: 'rgba(255, 255, 255, 0.0)',
		transition: 'background-color 0.2s ease, font-weight 0.3s ease',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.4)'
		},
		fontWeight: active ? 'bold' : 'normal'
	}),
	icon: ({ active }) => ({
		transition: 'transform 0.3s ease',
		transform: active ? 'scale(1.2)' : 'scale(1)'
	})
});

const BottomActionButton = ({
	icon,
	text,
	active,
	name,
	onClick,
	className,
	value,
	...rest
}) => {
	const c = useStyles({ active });
	return (
		<div
			className={clsx(c.root, className)}
			onClick={(e) => onClick(e, value)}
			{...rest}>
			<span className={c.icon}>{icon}</span>
			<span>{text}</span>
		</div>
	);
};

BottomActionButton.propTypes = {
	icon: PropTypes.object,
	text: PropTypes.string,
	active: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.string.isRequired
};

BottomActionButton.defaultProps = {
	icon: '',
	text: '',
	active: false,
	onClick: () => {}
};

export default BottomActionButton;
