import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from 'react-select/async';
import { makeStyles } from '@material-ui/styles';

import Card from '../ui/Card';
import IconButton from '../ui/IconButton';
import MenuItem from '../ui/MenuItem';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ViewListIcon from 'mdi-react/ViewListIcon';
import CheckboxMarkedIcon from 'mdi-react/CheckboxMarkedIcon';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#fff',
		display: 'flex',
		flexFlow: 'row',
		justifyContent: 'center',
		marginLeft: 24,
		marginRight: 24,
		left: 24,
		right: 24 + 24,
		zIndex: 1200
	},
	input: {
		flexGrow: 1,
		padding: 0
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden'
	},
	noOptionsMessage: {
		padding: `0 16px`
	},
	singleValue: {
		fontSize: 16
	},
	placeholder: {
		position: 'absolute',
		top: 2,
		left: 2,
		fontSize: 16
	},
	paper: {
		position: 'absolute',
		zIndex: 1201,
		marginTop: 8,
		left: 0,
		right: 0
	},
	divider: {
		height: 16
	}
});

const selectStyles = {
	control: ({ borderWidth, boxShadow, ...base }) => ({
		...base,
		borderWidth: 0,
		height: '100%'
	}),
	groupHeading: (base) => ({
		...base,
		fontSize: 20
	})
};

function NoOptionsMessage(props) {
	return (
		<h4
			className={props.selectProps.classes.noOptionsMessage}
			{...props.innerProps}>
			{props.children}
		</h4>
	);
}

const OptionIcon = ({ type }) => {
	switch (type) {
		case 'track':
			return <CheckboxMarkedIcon />;
		case 'list':
			return <ViewListIcon />;
		default:
			return null;
	}
};

function Option(props) {
	const data = props.data;

	return (
		<MenuItem
			ref={props.innerRef}
			selected={props.isFocused}
			component="div"
			style={{
				fontWeight: props.isSelected ? 500 : 400,
				flexFlow: 'row'
			}}
			{...props.innerProps}>
			<OptionIcon type={data.type} style={{ marginRight: 16 }} />
			{props.label}
		</MenuItem>
	);
}

function Placeholder(props) {
	return (
		<h3 className={props.selectProps.classes.placeholder} {...props.innerProps}>
			{props.children}
		</h3>
	);
}

function SingleValue(props) {
	const data = props.data;
	const tags = null;

	return (
		<MenuItem
			ref={props.innerRef}
			selected={props.isFocused}
			component="div"
			style={{
				fontWeight: props.isSelected ? 500 : 400,
				flexFlow: 'row'
			}}
			{...props.innerProps}>
			<OptionIcon type={data.type} style={{ marginRight: 16 }} />
			{data.label}
			{tags}
		</MenuItem>
	);
}

function ValueContainer(props) {
	return (
		<div className={props.selectProps.classes.valueContainer}>
			{props.children}
		</div>
	);
}

function Menu(props) {
	return (
		<Card
			style={{ zIndex: 3000 }}
			square
			className={props.selectProps.classes.paper}
			{...props.innerProps}>
			{props.children}
		</Card>
	);
}

const components = {
	Menu,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer
};

const SearchBar = ({
	loadOptions,
	onChange,
	onClear,
	icon,
	placeholder,
	value,
	optionRenderer
}) => {
	const classes = useStyles();
	const [selectedOption, setSelectedOption] = useState('');
	let updatedComponents = {};

	if (optionRenderer) {
		const { Option, ...rest } = components;
		updatedComponents = { ...rest, Option: optionRenderer };
	}

	if (!selectedOption && value) {
		setSelectedOption({ label: value, value });
	}

	const _onChange = (option, e) => {
		setSelectedOption(option);
		onChange(option, e);
	};

	const onKeyDown = (event) => {
		if (
			event.keyCode === 37 ||
			event.keyCode === 38 ||
			event.keyCode === 39 ||
			event.keyCode === 40
		) {
			event.preventDefault();
		}
	};

	return (
		<Card className={classes.root}>
			<IconButton
				variant="transparent"
				className={classes.button}
				aria-label="Search">
				{icon}
			</IconButton>
			<AsyncSelect
				styles={selectStyles}
				value={selectedOption}
				className={classes.input}
				classes={classes}
				loadOptions={loadOptions}
				components={updatedComponents}
				onChange={_onChange}
				placeholder={placeholder}
				onKeyDown={onKeyDown}
				isClearable
			/>
			<IconButton
				variant="transparent"
				className={classes.button}
				onClick={(e) => {
					setSelectedOption(null);
					onClear(e);
				}}
				aria-label="Clear">
				<CloseIcon />
			</IconButton>
		</Card>
	);
};

SearchBar.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			options: PropTypes.object.isRequired
		})
	),
	onChange: PropTypes.func,
	onClear: PropTypes.func
};

SearchBar.defaultProps = {
	icon: <MagnifyIcon />,
	options: [],
	placeholder: 'Search...',
	onChange: () => {},
	onClear: () => {}
};

export default SearchBar;
