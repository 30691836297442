import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import HelpCircleIcon from 'mdi-react/HelpCircleIcon';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexFlow: 'column',
		height: '100%'
	},
	title: {},
	subtitle: {}
});

const NotFound = ({ title, subtitle }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<HelpCircleIcon />
			<h3>{title}</h3>
			<p>{subtitle}</p>
		</div>
	);
};
NotFound.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired
};

export default NotFound;
