import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Loading from '../Loading/Loading';
import {
	getColor,
	getBackgroundImage,
	validateColor,
	getSize
} from '../utils/colors';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: ({ color, variant, disabled, size }) => ({
		...getSize(size),
		backgroundColor:
			variant === 'outlined'
				? disabled
					? '#eaeaea'
					: 'transparent'
				: variant === 'transparent'
				? 'transparent'
				: getColor(color),
		backgroundImage:
			variant === 'outlined' || variant === 'transparent'
				? 'transparent'
				: getBackgroundImage(color),
		border:
			variant === 'outlined' ? `1px solid ${getColor(color)}` : '0 solid #000',
		borderRadius: 4,
		cursor: 'pointer',
		color:
			variant === 'outlined' || variant === 'transparent'
				? getColor(color)
				: '#fff',
		fontSize: 15,
		padding: '0px 24px',
		boxShadow:
			variant === 'outlined' || variant === 'transparent'
				? 'unset'
				: '0 15px 30px -5px rgba(0, 0, 0, 0.25)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		transition:
			'box-shadow 0.3s ease, transform 0.3s ease, filter 0.3s ease, background-color 0.3s ease',
		'&:hover': {
			boxShadow:
				variant === 'outlined' || variant === 'transparent'
					? 'unset'
					: '0 15px 30px -12px rgba(0, 0, 0, 0.50)',
			backgroundColor:
				variant === 'outlined' || variant === 'transparent'
					? '#eaeaea'
					: getColor(color)
		},
		'&:focus': {
			outline: 'unset',
			boxShadow: '0px 7px 30px 0px rgba(15, 176, 214, 0.7)'
		},
		'&:active': {
			filter: disabled ? '' : 'brightness(1.2)',
			transform: disabled ? '' : 'scale(0.95)'
		}
	})
});

const Button = ({ children, onClick, isLoading, className, ...rest }) => {
	const c = useStyles(rest);
	return (
		<button className={clsx(c.root, className)} onClick={onClick} {...rest}>
			{isLoading ? (
				<Loading
					color={rest.color !== 'transparent' ? '#fff' : getColor(rest.color)}
				/>
			) : (
				<>{children}</>
			)}
		</button>
	);
};

Button.propTypes = {
	onClick: PropTypes.func,
	color: validateColor,
	variant: PropTypes.oneOf(['outlined', 'normal', 'transparent']),
	size: PropTypes.oneOf(['small', 'mini', 'large', 'normal']),
	disabled: PropTypes.bool
};

Button.defaultProps = {
	onClick: () => {},
	color: 'info',
	variant: 'normal',
	disabled: false,
	size: 'normal'
};

export default Button;
