import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '../ui/MenuItem';

import { obtainPlacePhotos } from '../../utils/utils';
import { getPhotos$ } from '../../services/Foursquare';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		width: 100,
		height: 100
	},
	image: {
		objectFit: 'none',
		width: 100,
		height: 100,
		borderRadius: 5
	},
	emptyImage: {
		width: 100,
		height: 100,
		backgroundColor: '#777',
		borderRadius: 5
	},
	description: {
		marginLeft: 16
	}
}));

const OptionRenderer = (props) => {
	const classes = useStyles();
	const [photo, setPhoto] = useState('');
	const subsRef = useRef(null);

	useEffect(() => {
		const obs = getPhotos$(props.data.data);
		subsRef.current = obs.subscribe((p) => {
			const obtainedPhoto = obtainPlacePhotos(p, 200)[0];
			setPhoto(obtainedPhoto ? obtainedPhoto : '');
			subsRef.current.unsubscribe();
		});
	}, [props.data]);

	// Component will unmount
	useEffect(() => {
		return () => {
			subsRef.current.unsubscribe();
		};
	}, []);

	return (
		<MenuItem
			ref={props.innerRef}
			selected={props.isFocused}
			component="div"
			style={{
				fontWeight: props.isSelected ? 500 : 400,
				flexFlow: 'row'
			}}
			{...props.innerProps}>
			{photo.length > 0 ? (
				<img className={classes.image} src={photo} alt={photo} />
			) : (
				<div className={classes.emptyImage} />
			)}
			<span className={classes.description}>{props.label}</span>
		</MenuItem>
	);
};

export default OptionRenderer;
