export const PLACES_ADD = 'PLACES_ADD';
export const PLACES_BULK_ADD = 'PLACES_BULK_ADD';
export const PLACES_BULK_UPDATE = 'PLACES_BULK_UPDATE';
export const PLACES_REMOVE = 'PLACES_REMOVE';
export const PLACES_UPDATE = 'PLACES_UPDATE';
export const PLACES_UNSHARE = 'PLACES_UNSHARE';
export const PLACES_SHARE = 'PLACES_SHARE';
export const PLACES_COMPLETE_ADD = 'PLACES_COMPLETE_ADD';

export const addPlaces = (payload) => ({
	type: PLACES_ADD,
	payload
});

export const addBulkPlaces = (payload) => ({
	type: PLACES_BULK_ADD,
	payload
});

export const removePlaces = (payload) => ({
	type: PLACES_REMOVE,
	payload
});

export const updatePlaces = (payload) => ({
	type: PLACES_UPDATE,
	payload
});

export const sharePlaces = (payload) => ({
	type: PLACES_SHARE,
	payload
});

export const unsharePlaces = (payload) => ({
	type: PLACES_UNSHARE,
	payload
});

export const updateBulkPlaces = (payload) => ({
	type: PLACES_BULK_UPDATE,
	payload
});

export const completeAddPlaces = (payload) => ({
	type: PLACES_COMPLETE_ADD,
	payload
});
