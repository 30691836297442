import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/Button';
import TextField from '../../ui/TextField';
import { withStyles } from '@material-ui/styles';
import { Spring, animated } from 'react-spring/renderprops';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import SearchBar from '../../../components/SearchBar/SearchBar';
import {
	getPlacesForSearchBar,
	getUsedTags$
} from '../../../sandboxes/Sandbox';
import { isEmpty, obtainPlacePhotos } from '../../../utils/utils';
import { getPhotos } from './../../../services/Foursquare';
import PlaceDescription from './../../PlaceDescription/PlaceDescription';
import PlaceMap from './../../../containers/PlaceMap/PlaceMap';
import ImagePlaceholder from './../../ImagePlaceholder/ImagePlaceholder';
import ImageSelectDialog from './../../dialogs/ImageSelectDialog/index';
import ChipInput from '../../../components/ChipInput/ChipInput';
import OptionRenderer from '../../../components/OptionRenderer/OptionRenderer';
import { getCities, getSavedCity, saveCity } from '../../../services/Cities';
import { geolocated } from 'react-geolocated';
import Fab from '../../ui/Fab';
import PlusIcon from 'mdi-react/PlusIcon';

const styles = () => ({
	root: {
		display: 'flex',
		flexFlow: 'column'
	},
	placeContainer: {
		display: 'flex',
		justifyContent: 'center'
	},
	newPlaceContainer: {
		display: 'flex',
		flexFlow: 'column'
	},
	textField: {
		marginLeft: 8,
		marginRight: 8
	},
	mapContainer: {
		width: '100%',
		height: 400,
		margin: '16px 0px'
	},
	searchBar: {
		margin: '24px 0px'
	},
	buttonContainer: {
		display: 'flex',
		flexFlow: 'row',
		margin: 8
	},
	button: {
		alignSelf: 'end'
	},
	imageContainer: {
		display: 'flex',
		justifyContent: 'center'
	},
	innerImageContainer: {
		width: 300,
		height: 300,
		margin: 16,
		display: 'inline-flex'
	},
	centeredTitle: {
		textAlign: 'center'
	},
	centeredButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '16px 0px'
	},
	chips: {
		marginBottom: 16
	},
	title: {
		marginLeft: 8
	}
});

class NewPlaceStep1 extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			addNewPlace: false,
			place: {},
			location: {
				lat: 0,
				lng: 0
			},
			photos: {},
			name: '',
			imageDialogOpen: false,
			photoLoading: false,
			tags: [],
			usedTags: [],
			city: ''
		};
		this.nameRef = React.createRef();
		this.descriptionRef = React.createRef();
	}

	componentWillMount = () => {
		// Obtain used tags
		getUsedTags$(this.props.auth).subscribe((usedTags) =>
			this.setState({ usedTags })
		);

		const city = getSavedCity();
		this.setState({ city });
	};

	componentWillReceiveProps(nextProps) {
		const { coords } = nextProps;
		if (coords && coords.latitude !== 0 && coords.longitude !== 0)
			this.setState({
				location: {
					lat: coords.latitude,
					lng: coords.longitude
				}
			});
	}

	handleOnChange = (option) => {
		const { place } = this.state;
		if (option && option.data) {
			this.setState({
				place: {
					...place,
					name: option.data.name,
					description: option.data.location.formattedAddress.join(', '),
					source: 'foursquare'
				},
				location: {
					lat: option.data.location.lat,
					lng: option.data.location.lng
				}
			});
			getPhotos(option.data).then((p) => {
				this.setState({ photos: p });
			});
		}
	};

	handlePlaceSelect = (place) => {
		const p = {
			name: place.name,
			description: place.description,
			photo: obtainPlacePhotos(this.state.photos, 345)[0],
			tags: this.state.tags
		};
		const { location } = this.state;
		this.props.onComplete(p, location, 'foursquare');
	};

	handleLocationChange = (location) => {
		this.setState({ location });
	};

	handleImageSelection = (img) => {
		const { place } = this.state;
		this.setState({
			place: { ...place, photo: img },
			imageDialogOpen: false,
			photoLoading: true
		});
	};

	handleOnComplete = () => {
		const { onComplete } = this.props;
		const { place, location, tags } = this.state;
		const name = this.nameRef.current.value;
		const description = this.descriptionRef.current.value;
		const updatedPlace = { ...place, name, description, tags };
		onComplete(updatedPlace, location, 'new');
	};

	handleOnCityChange = (city) => {
		this.setState({ city: city ? city.label : '' });
		saveCity(city);
	};

	render() {
		const { classes } = this.props;
		const {
			addNewPlace,
			place,
			photos,
			location,
			imageDialogOpen,
			photoLoading,
			usedTags,
			city
		} = this.state;

		return (
			<div className={classes.root}>
				{!addNewPlace && (
					<div>
						<h1 className={classes.title}>Select a place</h1>
						<div className={classes.searchBar}>
							<SearchBar
								loadOptions={(q) => getCities(q)}
								onChange={this.handleOnCityChange}
								placeholder="Search city"
								icon={<MapMarkerIcon />}
								value={city}
							/>
						</div>
						<Spring
							native
							from={{
								marginTop: city && city.length > 0 ? '-70px' : ' 0px',
								opacity: city && city.length > 0 ? 0 : 1,
								display: city && city.length > 0 ? 'none' : 'block'
							}}
							to={{
								marginTop: city && city.length > 0 ? '0px' : '-70px',
								opacity: city && city.length > 0 ? 1 : 0,
								display: city && city.length > 0 ? 'block' : 'none'
							}}>
							{(props) => {
								if (city && city.length > 0) {
									return (
										<animated.div style={props} className={classes.searchBar}>
											<SearchBar
												loadOptions={(query) =>
													getPlacesForSearchBar(city, query)
												}
												onChange={this.handleOnChange}
												placeholder="Search a place"
												optionRenderer={OptionRenderer}
											/>
										</animated.div>
									);
								} else {
									return null;
								}
							}}
						</Spring>
						{!isEmpty(place) && (
							<>
								<h4>Tags</h4>
								<div className={classes.chips}>
									<ChipInput
										options={usedTags}
										onChange={(t) => this.setState({ tags: t })}
									/>
								</div>
								<div className={classes.placeContainer}>
									<PlaceDescription
										place={place}
										photo={
											obtainPlacePhotos(photos, 345).length > 0
												? obtainPlacePhotos(photos, 345)[0]
												: ''
										}
										onPlaceSelect={this.handlePlaceSelect}
									/>
								</div>
								<h3>Locate on map</h3>
								<div className={classes.mapContainer}>
									<PlaceMap
										placeLocation={location}
										onLocationChange={this.handleLocationChange}
									/>
								</div>
							</>
						)}
						<div className={classes.newPlaceContainer}>
							<h4 className={classes.centeredTitle}>
								Didn't found what you were searching?
							</h4>
							<div className={classes.centeredButton}>
								<Fab onClick={() => this.setState({ addNewPlace: true })}>
									<PlusIcon />
									ADD PLACE
								</Fab>
							</div>
						</div>
					</div>
				)}
				{addNewPlace && (
					<div className={classes.newPlaceContainer}>
						<h2>Name your place:</h2>
						<TextField
							inputRef={this.nameRef}
							id="outlined-name"
							label="Name"
							className={classes.textField}
							margin="normal"
							variant="outlined"
							placeholder="Name"
						/>
						<TextField
							inputRef={this.descriptionRef}
							id="outlined-description"
							label="Description"
							className={classes.textField}
							margin="normal"
							variant="outlined"
							placeholder="Description"
						/>
						<h4>Tags</h4>
						<ChipInput
							options={usedTags}
							onChange={(t) => this.setState({ tags: t })}
						/>
						<h2>Select an image</h2>
						<div className={classes.imageContainer}>
							<div className={classes.innerImageContainer}>
								<ImagePlaceholder
									image={place.photo}
									onClick={() => this.setState({ imageDialogOpen: true })}
									loading={photoLoading}
									onLoaded={(e) => this.setState({ photoLoading: false })}
								/>
							</div>
						</div>
						<div className={classes.buttonContainer}>
							<Button
								className={classes.button}
								variant="normal"
								color="primary"
								onClick={this.handleOnComplete}>
								Next
							</Button>
						</div>
						<h3>Locate on map</h3>
						<div className={classes.mapContainer}>
							<PlaceMap
								placeLocation={{
									lat: location.lat ? location.lat : 0,
									lng: location.lng ? location.lng : 0
								}}
								onLocationChange={this.handleLocationChange}
							/>
						</div>
					</div>
				)}
				<ImageSelectDialog
					open={imageDialogOpen}
					onSelect={this.handleImageSelection}
					onClose={() => this.setState({ imageDialogOpen: false })}
				/>
			</div>
		);
	}
}

NewPlaceStep1.propTypes = {
	onComplete: PropTypes.func.isRequired
};

NewPlaceStep1.defaultProps = {
	onComplete: () => {}
};

export default withStyles(styles)(
	geolocated({ positionOptions: { enableHighAccuracy: false } })(NewPlaceStep1)
);
