import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import IconButton from '../ui/IconButton';
import { isNowBetweenHours } from '../../utils/text.utils';

import CheckCircleOutlineIcon from 'mdi-react/CheckCircleIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import ShareVariantIcon from 'mdi-react/ShareVariantIcon';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';

const useStyles = makeStyles({
	root: {
		margin: 16,
		display: 'inline-flex',
		boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
		transition: '0.3s',
		'&:hover': {
			boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
		},
		cursor: 'pointer',
		borderRadius: 12
	},
	image: {
		objectFit: 'cover',
		borderRadius: 12
	},
	descriptionContainer: {
		display: 'flex',
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
		color: '#fff',
		position: 'absolute',
		bottom: 16,
		left: 16,
		right: 16,
		padding: 8,
		borderBottomLeftRadius: 12,
		borderBottomRightRadius: 12
	},
	description: {
		display: 'flex',
		flexFlow: 'column'
	},
	actionButton: {
		alignSelf: 'center',
		right: 0,
		position: 'absolute'
	}
});

const PlaceItemIcon = ({ opens, closes }) => {
	const isOpen = isNowBetweenHours(new Date(), opens, closes);
	if (isOpen) {
		return <CheckCircleOutlineIcon style={{ margin: 'auto 8px' }} />;
	} else {
		return <CloseCircleOutlineIcon style={{ margin: 'auto 8px' }} />;
	}
};

const PlaceItem = ({
	placeData,
	placeId,
	place,
	width,
	height,
	onSelected,
	opens,
	closes,
	onShare,
	onSharedDetails
}) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.descriptionContainer}>
				<PlaceItemIcon opens={opens} closes={closes} />
				<div className={classes.description}>
					<span>
						<b>{place.name}</b>
					</span>
					<span>
						{opens} - {closes}
					</span>
				</div>
				{placeData.owner && (
					<IconButton
						variant="transparent"
						className={classes.actionButton}
						onClick={() => onShare(placeId)}>
						<ShareVariantIcon style={{ color: 'white' }} />
					</IconButton>
				)}
				{!placeData.owner && (
					<IconButton
						variant="transparent"
						className={classes.actionButton}
						onClick={() => onSharedDetails(placeId)}>
						<InformationOutlineIcon style={{ color: 'white' }} />
					</IconButton>
				)}
			</div>
			<img
				style={{ width, height }}
				className={classes.image}
				src={place.photo}
				alt={place.photo}
				onClick={() => onSelected(place)}
			/>
		</div>
	);
};

PlaceItem.propTypes = {
	place: PropTypes.shape({
		name: PropTypes.string,
		photo: PropTypes.string,
		id: PropTypes.string
	}),
	opens: PropTypes.string,
	closes: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	onShare: PropTypes.func,
	onSharedDetails: PropTypes.func
};

PlaceItem.defaultProps = {
	width: 300,
	height: 300,
	onSharedDetails: () => {}
};

export default PlaceItem;
