import React, { PureComponent } from 'react';
import ImageSelector from '../../components/ImageSelector/ImageSelector';

class ImagesSearch extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentWillMount = () => {};

	render() {
		return (
			<div className="ImagesSearchWrapper">
				<ImageSelector />
			</div>
		);
	}
}

export default ImagesSearch;
