import React from 'react';
import PropTypes from 'prop-types';
import './Checkbox.css';
import clsx from 'clsx';

const Checkbox = ({ title, value, checked, onChange, className, ...rest }) => {
	return (
		<label className={clsx('pure-material-checkbox', className)} {...rest}>
			<input type="checkbox" checked={checked} onClick={onChange} />
			{title && <span>{title}</span>}
		</label>
	);
};

Checkbox.propTypes = {
	onChange: PropTypes.func
};

Checkbox.defaultProps = {};

export default Checkbox;
