export const PLACES_REQUESTED = 'PLACES_REQUESTED';
export const PROFILE_REQUESTED = 'PROFILE_REQUESTED';

export const setPlacesRequested = () => ({
	type: PLACES_REQUESTED,
	payload: true
});

export const setProfileRequested = (payload) => ({
	type: PROFILE_REQUESTED,
	payload: true
});
