import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	root: {
		flexGrow: 0,
		minHeight: 64,
		backgroundImage:
			'linear-gradient(to right top, #0b84e1, #1076cd, #1168ba, #105ba7, #0d4e94)',
		display: 'flex',
		flexDirection: 'row',
		// borderRadius: '15px 15px 0 0',
		bottom: 0,
		width: '100%',
		zIndex: 1000
	}
});

const BottomBar = ({ children, value, onChange, ...rest }) => {
	const c = useStyles(rest);
	const [active, setActive] = useState(value);
	const handleActionChange = (e, value) => {
		setActive(value);
		onChange(value);
	};

	const updatedChildren = React.Children.map(children, (child) => {
		return React.cloneElement(child, {
			onClick: handleActionChange,
			active: child.props.value === active
		});
	});

	return <div className={c.root}>{updatedChildren}</div>;
};

BottomBar.propTypes = {
	onChange: PropTypes.func
};

BottomBar.defaultProps = {
	// bla: 'test',
};

export default BottomBar;
