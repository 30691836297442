import {
	SHARED_PLACES_ADD,
	SHARED_PLACES_BULK_ADD,
	SHARED_PLACES_BULK_UPDATE,
	SHARED_PLACES_REMOVE,
	SHARED_PLACES_UPDATE,
	SHARED_PLACES_COMPLETE_ADD
} from '../actions/SharedPlaces.actions';

export function sharedPlaces(state = [], action) {
	switch (action.type) {
		case SHARED_PLACES_ADD:
			let t = buildPlacesFromObject(action.payload);
			if (action.payload._tempId) {
				t._tempId = action.payload._tempId;
				return [...state, t];
			}
			return [...state, t];
		case SHARED_PLACES_BULK_ADD:
			const ids = state.map((p) => p.id);
			return state.concat(
				action.payload
					.filter((p) => ids.indexOf(p.id) < 0)
					.map((p) => buildPlacesFromObject(p))
			);
		case SHARED_PLACES_REMOVE:
			return state.filter((place) => place.id !== action.payload.id);
		case SHARED_PLACES_UPDATE:
			let index = state.map((place) => place.id).indexOf(action.payload.id);
			return [
				...state.slice(0, index),
				buildPlacesFromObject(action.payload),
				...state.slice(index + 1)
			];
		case SHARED_PLACES_BULK_UPDATE:
			return state.map((t) => {
				const ot = action.payload.filter((place) => place.id === t.id);
				if (ot && ot.length > 0) {
					return Object.assign({}, ot[0]);
				} else {
					return t;
				}
			});
		case SHARED_PLACES_COMPLETE_ADD:
			let i = state
				.map((place) => (place._tempId === undefined ? 0 : place._tempId))
				.indexOf(action.payload._tempId);
			return [
				...state.slice(0, i),
				buildPlacesFromObject(action.payload),
				...state.slice(i + 1)
			];
		default:
			return state;
	}
}

const buildPlacesFromObject = (obj) => {
	let t = {
		id: obj.id,
		owner: obj.owner,
		sharedBy: obj.sharedBy,
		place: {
			name: obj.place.name,
			description: obj.place.description,
			photo: obj.place.photo,
			tags: obj.place.tags
		},
		schedule: {
			mon: obj.schedule.mon,
			tue: obj.schedule.tue,
			wed: obj.schedule.wed,
			thu: obj.schedule.thu,
			fri: obj.schedule.fri,
			sat: obj.schedule.sat,
			sun: obj.schedule.sun
		},
		location: {
			lat: obj.location.lat,
			lng: obj.location.lng
		}
	};
	return t;
};
