import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer, Marker } from 'react-leaflet';

const parseLocation = (location) => {
	if (location) {
		return location;
	} else {
		return {
			lat: 0,
			lng: 0
		};
	}
};

const PlaceMap = ({
	placeLocation,
	disableZoomChange,
	disableLocationChange,
	onLocationChange,
	disable,
	zoom
}) => {
	const [location, setLocation] = useState(parseLocation(placeLocation));
	const [z, setZ] = useState(13);

	const mapRef = useRef(null);

	useEffect(() => {
		const l = parseLocation(placeLocation);
		if (l) {
			setLocation(l);
		}
		return () => {};
	}, [placeLocation]);

	useEffect(() => {
		setZ(zoom);
	}, [zoom]);

	const handleLocationFound = (e) => {
		setLocation(e.latlng);
	};

	const handleClick = (e) => {
		if (!disableLocationChange) {
			setLocation(e.latlng);
			onLocationChange(e.latlng);
		}
	};

	const handleZoomChange = (e) => {
		setZ(e.zoom);
	};

	return (
		<Map
			center={location}
			length={4}
			onClick={handleClick}
			onLocationfound={handleLocationFound}
			ref={mapRef}
			zoom={z}
			onZoomanim={handleZoomChange}
			doubleClickZoom={!disableZoomChange}
			scrollWheelZoom={!disableZoomChange}
			boxZoom={!disableZoomChange}
			touchZoom={!disableZoomChange}
			dragging={!disable}>
			<TileLayer
				attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			{location && <Marker position={location} />}
		</Map>
	);
};

PlaceMap.propTypes = {
	placeLocation: PropTypes.object.isRequired,
	disableLocationChange: PropTypes.bool,
	disableZoomChange: PropTypes.bool,
	disable: PropTypes.bool,
	onLocationChange: PropTypes.func.isRequired
};

PlaceMap.defaultProps = {
	placeLocation: {},
	disableLocationChange: false,
	disableZoomChange: false,
	disable: false,
	onLocationChange: () => {}
};

export default PlaceMap;
