import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTrail, animated } from 'react-spring';
import PropTypes from 'prop-types';
import { getFormattedDayOfWeek } from '../../utils/text.utils';

import PlaceItem from '../PlaceItem/PlaceItem';
import SharePlaceDialog from '../dialogs/SharePlaceDialog';
import { sharePlace$, unsharePlace$ } from '../../sandboxes/Sandbox';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		overflow: 'hidden',
		backgroundColor: '#fff',
		flexGrow: 1,
		'@media(max-width: 641px)': {
			justifyContent: 'space-around'
		}
	}
}));

// const config = { mass: 5, tension: 1000, friction: 100 };

const Places = ({ auth, places, onPlaceClick }) => {
	const classes = useStyles();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [selectedPlaceId, setSelectedPlaceId] = useState('');

	const trail = useTrail(places.length, {
		from: { opacity: 0, transform: 'scale(0.6)' },
		to: { opacity: 1, transform: 'scale(1)' }
	});

	const handleOnPlaceShare = (user, placeId) => {
		sharePlace$(auth, user, placeId).subscribe((res) => {});
	};

	const handleOnPlaceUnshare = (user, placeId) => {
		unsharePlace$(auth, placeId, user).subscribe();
	};

	let sharedWith = [];

	const place =
		selectedPlaceId.length > 0
			? places.filter((p) => p.id === selectedPlaceId)[0]
			: null;
	if (place) {
		sharedWith = place.sharedWith;
	}

	return (
		<div className={classes.root}>
			{trail.map((props, i) => (
				<animated.div key={places[i].id} style={props}>
					<PlaceItem
						placeData={places[i]}
						placeId={places[i].id}
						place={places[i].place}
						opens={places[i].schedule[getFormattedDayOfWeek()].opens}
						closes={places[i].schedule[getFormattedDayOfWeek()].closes}
						width={300}
						height={300}
						onSelected={() => onPlaceClick(places[i])}
						onShare={(placeId) => {
							setDialogOpen(true);
							setSelectedPlaceId(placeId);
						}}
					/>
				</animated.div>
			))}
			<SharePlaceDialog
				auth={auth}
				open={dialogOpen}
				sharedWith={sharedWith}
				placeId={selectedPlaceId}
				onClose={() => setDialogOpen(false)}
				onSelect={(user, placeId) => handleOnPlaceShare(user, placeId)}
				onUnshare={handleOnPlaceUnshare}
			/>
		</div>
	);
};

Places.propTypes = {
	places: PropTypes.array.isRequired,
	onPlaceClick: PropTypes.func
};

Places.defaultProps = {
	places: [],
	onPlaceClick: () => {},
	onEdit: () => {}
};

export default Places;
