import React, { PureComponent } from 'react';

import NewPlaceStep1 from './../../components/NewPlaceForm/NewPlaceStep1/NewPlaceStep1';
import NewPlaceStep2 from './../../components/NewPlaceForm/NewPlaceStep2/NewPlaceStep2';
import { saveNewPlace$ } from '../../sandboxes/Sandbox';
import withAuth from './../../hocs/auth/withAuth';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
	root: {
		width: '100%'
	}
});

class NewPlace extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			step: 1,
			place: null,
			schedule: null,
			location: null
		};
	}

	onStep1Complete = (place, location, source) => {
		const p = {
			...place,
			source
		};
		this.setState({ place: p, location, step: 2 });
	};

	onStep2Complete = (schedule) => {
		const { place, location } = this.state;
		saveNewPlace$(this.props.auth, { place, location, schedule }).subscribe(
			() => this.props.history.replace('/m/dashboard')
		);
	};

	render() {
		const { step } = this.state;

		return (
			<div>
				{step === 1 && <NewPlaceStep1 onComplete={this.onStep1Complete} />}
				{step === 2 && <NewPlaceStep2 onComplete={this.onStep2Complete} />}
			</div>
		);
	}
}

export default withAuth(withStyles(styles)(NewPlace));
